import axios from "axios";
import axiosApi from "../axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { importDataParse, importDataParseExtended } from "../utils";
import history from "../history";

export const moduleName = "import";

/*                                      ACTIONS                                              */

export const REQUEST_IMPORT_DATA = moduleName + "/REQUEST_IMPORT_DATA";
export const RECEIVE_IMPORT_DATA = moduleName + "/RECEIVE_IMPORT_DATA";
export const ERROR_IMPORT_DATA = moduleName + "/ERROR_IMPORT_DATA";

export function requestImportData(file) {
  return { type: REQUEST_IMPORT_DATA, payload: file };
}

export function receiveImportData(
  data = null,
  errors = null,
  errorsCount = null,
  ordersWithErrors = null,
  type = null
) {
  return {
    type: RECEIVE_IMPORT_DATA,
    payload: { data, errors, errorsCount, ordersWithErrors, type }
  };
}

export function errorImportData(error) {
  return { type: ERROR_IMPORT_DATA, payload: error };
}

/*                                           SAGAS                                           */

export const getImportData = async file => {
  let formData = new FormData();
  let server = process.env.REACT_APP_API_HOSTNAME === "test" ? "test" : "dev";
  formData.append("excel", file);
  formData.append("server", "prod");
  formData.append("version", "x20");
  formData.append("operation", "verify");
  formData.append("sender_name", "sender_name_test");
  return axios.post("https://nodejs.biocard.com/user/fileUpload", formData, {
    headers: {
      "cache-control": "no-cache",
      "Content-Type": "multipart/form-data"
    }
  });
};

function* importEffectSaga(action) {
  try {
    let { data } = yield call(getImportData, action.payload);
    let { dataForTable, errors } =
      data.err_statistic.xlsx_type === "ext"
        ? importDataParseExtended(data.json)
        : importDataParse(data.json);
    let errorsCount = data.err_statistic.errors_in_excel_false;
    let ordersWithErrors = errors.reduce((prev, cur) => {
      if (cur.length > 0) {
        prev += 1;
      }
      return prev;
    }, 0);

    yield put(
      receiveImportData(
        dataForTable,
        errors,
        errorsCount,
        ordersWithErrors,
        data.err_statistic.xlsx_type
      )
    );
  } catch (e) {
    yield put(errorImportData(e));
    console.log(e);
  }
}

export function* importWatcherSaga() {
  yield takeLatest(REQUEST_IMPORT_DATA, importEffectSaga);
}

/*                                      ACTIONS                                              */
export const REQUEST_IMPORT_CHECK_DATA =
  moduleName + "/REQUEST_IMPORT_CHECK_DATA";

export function requestImportCheckData(data) {
  return { type: REQUEST_IMPORT_CHECK_DATA, payload: data };
}

/*                                        SAGAS                                               */

export const getImportCheckData = async data => {
  return axiosApi.post("json4/x20.php", data);
};

function* importCheckEffectSaga(action) {
  try {
    let { data } = yield call(getImportCheckData, action.payload);
    let { dataForTable, errors } =
      data.err_statistic.xlsx_type === "ext"
        ? importDataParseExtended(data.json)
        : importDataParse(data.json);
    let errorsCount = data.err_statistic.errors_in_excel_false;
    let ordersWithErrors = errors.reduce((prev, cur) => {
      if (cur.some(el => !el)) {
        prev += 1;
      }
      return prev;
    }, 0);
    yield put(
      receiveImportData(dataForTable, errors, errorsCount, ordersWithErrors, data.err_statistic.xlsx_type)
    );
    if (data.err_statistic.result_message === "save ok") {
      console.log(data.err_statistic.result_message);
      history.push("/");
    }
  } catch (e) {
    yield put(errorImportData(e));
    console.log(e);
  }
}

export function* importCheckWatcherSaga() {
  yield takeLatest(REQUEST_IMPORT_CHECK_DATA, importCheckEffectSaga);
}

export const initialState = {
  data: null,
  loading: false,
  error: null,
  errors: null,
  errorsCount: null,
  ordersWithErrors: null,
  type: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_IMPORT_DATA:
      return {
        ...state,
        data: action.payload.data,
        errors: action.payload.errors,
        loading: false,
        errorsCount: action.payload.errorsCount,
        ordersWithErrors: action.payload.ordersWithErrors,
        type: action.payload.type
      };
    case REQUEST_IMPORT_DATA:
      return {
        ...state,
        loading: true
      };
    case REQUEST_IMPORT_CHECK_DATA:
      return {
        ...state,
        loading: true
      };
    case ERROR_IMPORT_DATA:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
