const drawerWidth = 240;

const styles = (theme) => ({
	appBar: {
		background: 'white',
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	grow: {
		// flexGrow: 1
		width: '100%'
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36
	},
	title: {
		margin: 'auto',
		display: 'flex',
		justifyContent: 'center',
		width: 173,
		[theme.breakpoints.up('sm')]: {
			display: 'block'
		},
		height: '50px'
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex'
		},
		alignItems: 'center',
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
});

export default styles;
