import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { loginWatcher, logoutWatcher, updateProfile } from '../../sagas/sessionSaga';
import { receiveOrders } from '../../sagas/deliverySaga';
//components
import NavBarComponent from './navBar/NavBarContainer';
import SideDrawerComponent from './navBar/drawer/sideDrawer';

import { getCookie, setCookie } from '../../utils';

import history from '../../history';

class Layout extends Component {
	state = {
		sideDrawer: null
	};

	componentDidMount() {
		this.setState({ sideDrawer: !!getCookie('sideDrawer') }); // set sideDrawer from user cookie
	}

	handleLogout = () => {
		this.props.receiveOrders();
		this.props.logoutWatcher();
	};

	handleDrawerOpen = () => {
		this.setState((prevState) => {
			if (!prevState.sideDrawer) {
				setCookie('sideDrawer', 1);
			} else {
				setCookie('sideDrawer', '');
			}

			return { sideDrawer: !prevState.sideDrawer };
		});
	};

	render() {
		return (
			<React.Fragment>
				<NavBarComponent
					handleLogout={this.handleLogout}
					handleDrawerOpen={history.location.pathname.includes('import') ? () => history.push('/transportation') : this.handleDrawerOpen}
					open={this.state.sideDrawer}
					profile={this.props.profile}
				/>
				{history.location.pathname.includes('import') ? null : (
					<React.Fragment>
					{/* <Breadcrumbs /> */}
					<SideDrawerComponent open={this.state.sideDrawer} />
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.auth.profile
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			loginWatcher,
			logoutWatcher,
			updateProfile,
			receiveOrders,
			// add other watcher sagas to this object to map them to props
		},
		dispatch
	);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
