const data = {
  transportation: [
    {
      title: "Courier shipments history",
      data:
        "Please spend 1 minute to check Hints. It will help you to work with online service. Hints are different on every page."
    },
    {
      title: "Courier shipments history",
      data:
        "Search-bar on the top right corner can be used to find any data displayed on the page"
    },
    {
      title: "Courier shipments history",
      data: 
          "You can copy your order. New one will be the same, so you'll just need to make some necessary corrections and place an order."
      
    },
    {
      title: "Courier shipments history",
      data:
        "You can download an Excel table with necessary orders by clicking an icon on the top right corner."
    }
  ],
  "create-order": [
    {
      title: "Страница создания заявки, контакты",
      data:
        "Обратите внимание на подсказки при вводе адреса, они помогут вам сэкономить время заполнения заявки"
    },
    {
      title: "Страница создания заявки, контакты",
      data:
        "Минимальные даты отправления и получения будут рассчитаны автоматически исходя из адресов в заявке"
    },
    {
      title: "Страница создания заявки, грузы",
      data:
        "У вас есть возможность заказать отдельный автотранспорт указанной тонажности, в меню справа, без добавления грузовых мест в заявку"
    }
  ],
  login: null,
  "single-order": [
    {
      title: "Детальня страница заявки",
      data: "Вы можете скопировать заявку нажав на иконку. Новая заявка будет содержать все скопированные данные, останется только добавить необходимые изменения, и отправить."
    },
    {
      title: "Детальня страница заявки",
      data: "Вы можете оставить нам отзыв нажав на иконку. Все отзывы будут рассмотрены, и приняты меры по улучшению работы личного кабинета."
    }
  ],
  store: [
    {
      title: "Страница склада",
      data: 'You can switch pages by clicking tabs on the top'
    },
    {
      title: "Страница склада",
      data: 'You can change a project by clicking currently chosen one on the top'
    },
    {
      title: "Страница склада",
      data: 
        'Search-bar on the top right corner can be used to find any data displayed on the page'
      
    }
  ]
};

export default data;
