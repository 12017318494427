import React, { Component } from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";

import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//components
import Routes from "./routes/routes";
import ErrorBoundary from "./containers/errors/errorBoundary";
import Layout from "./containers/layout/Layout";
import Insructions from "./containers/layout/navBar/drawer/instructions/inscructionCard";

import history from "./history";

import "normalize.css";

import styles from "./AppStyles.js";

import theme from './MyTheme';

class App extends Component {
  render() {
    const { classes, profile } = this.props;
    return (
      <div className={classes.root}>
       <Insructions />
        <ErrorBoundary>
          <MuiThemeProvider theme={theme}>
            <div className={classes.content}>
              {history.location.pathname.includes("/login") ? null : <Layout />}
             
				  <Routes authorised={profile.result_message_name === "ok"} />
				  {/* <Routes authorised /> */}
            </div>
          </MuiThemeProvider>
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.auth.profile
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      push
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(App))
);
