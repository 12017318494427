import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import RedirectToSingle from "../components/redirect/redirect";
import ProtectedRoute from "./protectedRoute";
import Spinner from "../components/spinner/spinner";

const StoreContainer = React.lazy(() =>
  import("../containers/store/StoreContainer")
);
const DeliveryContainer = React.lazy(() =>
  import("../containers/delivery/deliveryList/DeliveryContainer")
);
const LoginComponent = React.lazy(() => import("../containers/auth/login"));
const CreateOrder = React.lazy(() =>
  import("../containers/createOrder/createOrderHeader/createOrderHeader")
);
const SinglePage = React.lazy(() =>
  import("../containers/delivery/singleOrderContainer/singleOrderPage")
);
const ImportTable = React.lazy(() => import("../containers/import/import"));
const SensorHistory = React.lazy(() =>
  import("../containers/delivery/singleOrderContainer/map")
);
const StoreOrderPage = React.lazy(() =>
  import("../containers/store/storeOrderSinglePage")
);
const CustomsContainer = React.lazy(() =>
  import("../containers/customs/customsOrderCreate")
);
const CustomsList = React.lazy(() =>
  import("../containers/customs/customsList")
);
const CustomsSinglePage = React.lazy(() =>
  import("../containers/customs/customsSinglePage/customsSinglePage")
);
const RegulList = React.lazy(() =>
  import("../containers/regulatory/regulList")
);
const PurchaseContainer = React.lazy(() =>
  import("../containers/purhase/PurchaseContainer")
);
const ServiceAgreement = React.lazy(() =>
  import("../containers/serviceAgreement")
);

const Routes = ({ authorised }) => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route
        auth={authorised}
        path="/single-order/noauth/:id"
        component={obj => <SinglePage match={obj.match} />}
      />
      <Route
        path="/service-agreement/"
        component={obj => <ServiceAgreement match={obj.match} />}
      />
      <ProtectedRoute
        auth={authorised}
        exact
        path="/transportation"
        component={() => <DeliveryContainer />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/create-order/:id"
        component={obj => <CreateOrder match={obj.match} />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/single-order/:id"
        component={obj => <SinglePage match={obj.match} />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/sensor-history/:id/:doc_id/:sn_smart/:iduser1c"
        component={obj => <SensorHistory match={obj.match} />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/store-order/:id"
        component={obj => <StoreOrderPage match={obj.match} />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/create-order"
        component={() => <CreateOrder />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/import"
        component={() => <ImportTable />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/store"
        component={() => <StoreContainer />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/customs/create"
        component={() => <CustomsContainer />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/customs/history"
        component={() => <CustomsList />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/customs-order/:id"
        component={obj => <CustomsSinglePage match={obj.match} />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/regul/history"
        component={() => <RegulList />}
      />
      <ProtectedRoute
        auth={authorised}
        path="/purchasing/history"
        component={() => <PurchaseContainer />}
      />
      <Route path="/vieworder" render={obj => <RedirectToSingle obj={obj} />} />
      <Route path="/login" component={() => <LoginComponent />} />
      <Redirect from="/" to="/transportation" />
    </Switch>
  </Suspense>
);

export default Routes;
