import axios from "../axios";
import { takeLatest, call, put, throttle } from "redux-saga/effects";

import { statusMap } from "../utils/data";

export const moduleName = "store";

/*                                            ACTIONS                                            */

export const REQUEST_ALL_PROJECTS = `${moduleName}/REQUEST_ALL_PROJECTS`;
export const RECEIVE_ALL_PROJECTS = `${moduleName}/RECEIVE_ALL_PROJECTS`;
export const ERROR_ALL_PROJECTS = `${moduleName}/ERROR_ALL_PROJECTS`;
export const RECEIVE_ONE_PROJECT = `${moduleName}/RECEIVE_ONE_PROJECT`;

export function requestProjects(userId, iduser1c) {
  return { type: REQUEST_ALL_PROJECTS, payload: { userId, iduser1c } };
}

export function receiveProjects(projects) {
  return { type: RECEIVE_ALL_PROJECTS, payload: projects };
}

export function errorProjects() {
  return { type: ERROR_ALL_PROJECTS, payload: null };
}

export const receiveOneProject = project => {
  return { type: RECEIVE_ONE_PROJECT, payload: project };
};

/*                                           SAGAS                                           */

export const getProjectsStock = async ({ userId, iduser1c }) => {
  let formData = new FormData();
  formData.append("ClientID", userId);
  formData.append("iduser1c", iduser1c);
  return await axios.post("/stock/stock_prj_list_01.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export function* projectsEffectSaga(action) {
  try {
    let { data } = yield call(getProjectsStock, action.payload);
    data = data.data.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.ProektName]: { id: cur.ProektID, closed: cur.ProektClosed }
      };
    }, {});
    yield put(receiveProjects(data));
  } catch (e) {
    console.error(e);
  }
}

export function* projectsWatcherSaga() {
  yield takeLatest(REQUEST_ALL_PROJECTS, projectsEffectSaga);
}

/*                                            ACTIONS                                            */

export const REQUEST_ALL_STOCKS = `${moduleName}/REQUEST_ALL_STOCKS`;
export const RECEIVE_ALL_STOCKS = `${moduleName}/RECEIVE_ALL_STOCKS`;
export const UPDATE_STOCKS = `${moduleName}/UPDATE_STOCKS`;

export function requestStocks(projectId) {
  return { type: REQUEST_ALL_STOCKS, payload: projectId };
}

export function receiveStocks(stocks) {
  return { type: RECEIVE_ALL_STOCKS, payload: stocks };
}

export function updateStocks(id, value, seriesId) {
  return { type: UPDATE_STOCKS, payload: { id, value, seriesId } };
}

export const getStocks = async project => {
  let formData = new FormData();
  formData.append("project_erpId", project);
  return await axios.post(
    "/stock/stock_get_all_for_prj_group_02_1.php",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

/*                                           SAGAS                                           */

function* stocksEffectSaga(action) {
  try {
    let { data } = yield call(getStocks, action.payload);
    data = data.data.reduce((prev, cur, i) => {
      cur.goodsSeries_dateCreate = cur.goodsSeries_dateCreate
        ? cur.goodsSeries_dateCreate
        : "н/д";
      if (Object.keys(prev).includes(cur.goods_erpId)) {
        prev[cur.goods_erpId].quantity =
          Number(prev[cur.goods_erpId].quantity) + Number(cur.quant);
        prev[cur.goods_erpId].series.push({
          seriesName: cur.series_name,
          dueTo: cur.goodsSeries_dateGodnosty.slice(0, -9),
          stock: cur.amountStock,
          carantine: cur.amountQuarant,
          returned: cur.amountReturn,
          onOrder: 0,
          series_erpId: cur.series_erpId,
          sn_erpId: cur.sn_erpId,
          sn: cur.sn
        });
        return prev;
      }
      return {
        ...prev,
        [cur.goods_erpId]: {
          name: cur.goods_name,
          temperature: cur.temperature_name,
          quantity: Number(cur.quant),
          type: cur.goodsTypes_name,
          goods_erpId: cur.goods_erpId,
          stock: cur.amountStock,
          carantine: cur.amountQuarant,
          returned: cur.amountReturn,
          num: cur.num,
          series: [
            {
              seriesName: cur.series_name,
              dueTo: cur.goodsSeries_dateGodnosty.slice(0, -9),
              stock: cur.amountStock,
              carantine: cur.amountQuarant,
              returned: cur.amountReturn,
              onOrder: 0,
              series_erpId: cur.series_erpId,
              sn_erpId: cur.sn_erpId,
              sn: cur.sn
            }
          ]
        }
      };
    }, {});
    yield put({ type: "@@redux-form/DESTROY" }); //clear form on unmount
    yield put(receiveStocks(data));
  } catch (e) {
    console.error(e);
  }
}

export function* stocksWatcherSaga() {
  yield takeLatest(REQUEST_ALL_STOCKS, stocksEffectSaga);
}

/*                                            ACTIONS                                            */

export const REQUEST_ALL_CENTERS = `${moduleName}/REQUEST_ALL_CENTERS`;
export const RECEIVE_ALL_CENTERS = `${moduleName}/RECEIVE_ALL_CENTERS`;

export function receiveCenters(centers) {
  return { type: RECEIVE_ALL_CENTERS, payload: centers };
}

export function requestCenters(projectId) {
  return { type: REQUEST_ALL_CENTERS, payload: projectId };
}

/*                                           SAGAS                                           */

export const getCenters = async project => {
  let formData = new FormData();
  formData.append("project_erpId", project);
  return await axios.post("/stock/stock_get_centers_list_01.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

function* centersEffectSaga(action) {
  try {
    let { data } = yield call(getCenters, action.payload);

    data = data.data.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.erpId]: {
          centerName: cur.name,
          id: cur.erpId,
          name: cur.recipient_name,
          companyName: cur.company_name,
          contacts: cur.contacts,
          // phone: cur.recipient_phone,
          address: cur.address_name
        }
      };
    }, {});
    yield put(receiveCenters(data));
  } catch (e) {
    console.error(e);
  }
}

export function* centersWatcherSaga() {
  yield takeLatest(REQUEST_ALL_CENTERS, centersEffectSaga);
}

/*                                            ACTIONS                                            */

export const REQUEST_ALL_ORDERS = `${moduleName}/REQUEST_ALL_ORDERS`;
export const RECEIVE_ALL_ORDERS = `${moduleName}/RECEIVE_ALL_ORDERS`;

export function receiveOrders(orders) {
  return { type: RECEIVE_ALL_ORDERS, payload: orders };
}

export function requestOrders(project_uuid, search = "", date_from, date_to) {
  return {
    type: REQUEST_ALL_ORDERS,
    payload: { project_uuid, search, date_from, date_to }
  };
}

/*                                           SAGAS                                           */

export const getOrders = async ({
  project_uuid,
  search,
  date_from,
  date_to
}) => {
  let data = {
    project_uuid,
    search,
    date_to,
    date_from
  };
  return await axios.post(
    "/stock/requests_for_shipment_from_stock_03.php",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

function* ordersEffectSaga(action) {
  try {
    let { data } = yield call(getOrders, action.payload);
    data = data.data.reduce((prev, cur) => {
      // let status = statusMap[cur.status];
      return {
        ...prev,
        [cur.id]: {
          // date: cur.currenttimestamp_ddmmyyyy,
          status: cur.status,
          project: cur.proekt_name,
          sender_address: cur.sender_address_full,
          receive_to: cur.recipient_date_end,
          receiver: cur.recipient_name,
          receiver_address: cur.recipient_address_full,
          doc_id: cur.doc_id,
          numbercis: cur.numbercis,
          datecis: cur.datecis,
          uid: cur.uid,
          center_name: cur.center_name,
          order_new_comment: cur.order_new_comment,
          shipment: cur.shipment
        }
      };
    }, {});

    yield put(receiveOrders(data));
  } catch (e) {
    console.error(e);
  }
}

export function* storeOrdersWatcherSaga() {
  yield throttle(700, REQUEST_ALL_ORDERS, ordersEffectSaga);
}

export const initialState = {
  projects: {},
  project: "",
  stocks: {},
  centers: {},
  orders: {},
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ALL_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false
      };
    case REQUEST_ALL_PROJECTS:
      return {
        ...state,
        loading: true
      };
    case RECEIVE_ALL_STOCKS:
      return {
        ...state,
        stocks: action.payload,
        loading: false
      };
    case REQUEST_ALL_STOCKS:
      return {
        ...state,
        loading: true
      };
    case REQUEST_ALL_ORDERS:
      return {
        ...state,
        loading: true
      };
    case RECEIVE_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false
      };
    case UPDATE_STOCKS:
      let { id, value, seriesId } = action.payload;
      let newStocks = { ...state.stocks };
      let newStock = { ...newStocks[id] };
      let series = [...newStock.series];
      let serie = { ...series[seriesId] };
      if (+value > +state.stocks[id].series[seriesId].stock) {
        //check if stock less than order, maybe check in other place
        value = state.stocks[id].series[seriesId].stock;
      }
      if (+value < 0) {
        value = 0;
      }
      serie.onOrder = value;
      series[seriesId] = serie;
      newStock.series = series;
      newStocks[id] = newStock;
      return {
        ...state,
        stocks: newStocks
      };
    case REQUEST_ALL_CENTERS:
      return {
        ...state,
        loading: true
      };
    case RECEIVE_ALL_CENTERS:
      return {
        ...state,
        centers: action.payload,
        loading: false
      };
    case RECEIVE_ONE_PROJECT:
      return {
        ...state,
        project: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
