import axios from "../axios";

export const postErrorToDb = async (userId, error, errorInfo, browser) => {
  let formData = new FormData();
  formData.append("error_text_1", error);
  formData.append("error_text_2", errorInfo);
  formData.append("browser", browser);
  formData.append("user_uuid", userId);
  return await axios.post("/log/save_react_errors.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getBoxes = async () => {
  let formData = new FormData();
  formData.append("key", "1b317c56-33de-4e0b-9b65-f1b4a08b1b3a");
  return await axios.post("/book/typecargo.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getDadataAddressChoice = async query => {
  let formData = new FormData();
  formData.append("key", "1b317c56-33de-4e0b-9b65-f1b4a08b1b3a");
  formData.append("proekt_id", "ab1b7248-fa8f-11e7-80d4-ac1f6b00d3ad");
  formData.append("project_uuid", "ab1b7248-fa8f-11e7-80d4-ac1f6b00d3ad");
  formData.append("partner_type", "is_sender");
  formData.append("search_address", query);
  formData.append("iduser1c", "5d082b52-45e9-11e7-80cf-0cc47ac15091");
  formData.append("session_auth_key", "test");
  formData.append("session_lead_key", "test");
  formData.append("in_type", "dadata");
  return axios.post("/book/dadata_prod_02.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};
