import { useState } from "react";

export const instructionHook = (open) => {
  const [data, setData] = useState([
    {
      title: "First title",
      data: "First data"
    },
    {
      title: "Second title",
      data: "Second data"
    }
  ]);
  const [openHelp, setOpen] = useState(open);
  const [page, setPage] = useState(0);

  const handleOpenInstructions = () => {
    setOpen(!openHelp);
    localStorage.setItem("openInstructions", openHelp ? "" : " ");
  };

  return {
    data,
    handleOpenInstructions,
    page,
    openHelp,
    setOpen,
    setPage
  };
};
