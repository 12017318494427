import { all } from "redux-saga/effects";

import { loginWatcherSaga, logoutWatcherSaga } from "./sessionSaga";

import { importWatcherSaga, importCheckWatcherSaga } from "./importSaga";

import {
  projectsWatcherSaga,
  stocksWatcherSaga,
  centersWatcherSaga,
  storeOrdersWatcherSaga
} from "./storeSaga";

import {
  ordersWatcherSaga,
  projectsOrdersWatcherSaga,
  oneOrderWatcherSaga
} from "./deliverySaga";

import {
  ordersWatcherSaga as customsWatcherSaga,
  projectsOrdersWatcherSaga as customsProjectsWatcherSaga,
  oneOrderWatcherSaga as customsOrderWatcherSaga
} from "./customsSaga";

import {
  ordersWatcherSaga as regulOrdersWatcherSaga,
  projectsOrdersWatcherSaga as regulProjectsWatcherSaga,
  oneOrderWatcherSaga as regulOrderWatcherSaga
} from "./regulSaga";

import { addressesWatcherSaga } from "./createOrderSaga";

import { allPurchaseWatcher } from "./purchaseSaga";

export default function* rootSaga() {
  yield all([
    loginWatcherSaga(),
    logoutWatcherSaga(),
    projectsWatcherSaga(),
    stocksWatcherSaga(),
    ordersWatcherSaga(),
    projectsOrdersWatcherSaga(),
    addressesWatcherSaga(),
    oneOrderWatcherSaga(),
    centersWatcherSaga(),
    importWatcherSaga(),
    importCheckWatcherSaga(),
    storeOrdersWatcherSaga(),
    customsWatcherSaga(),
    customsProjectsWatcherSaga(),
    customsOrderWatcherSaga(),
    regulOrdersWatcherSaga(),
    regulProjectsWatcherSaga(),
    regulOrderWatcherSaga(),
    allPurchaseWatcher()
  ]);
}
