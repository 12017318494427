import { temperatureMap } from "./data";

export function debounce(f, ms) {
  let timer = null;

  return function(...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
}

export const getTimeFormated = dateObj => {
  let dateTime = new Date(dateObj).toLocaleDateString("en-GB", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });
  let date, time;
  if (window.navigator.userAgent.indexOf("Trident/7.0") > -1) {
    date = dateTime
      .split(" ")[0]
      .split("/")
      .reverse()
      .join("-");
    time = dateTime.split(" ")[1];
  } else {
    date = dateTime
      .split(",")[0]
      .split("/")
      .reverse()
      .join("-");
    time = dateTime.split(",")[1];
  }

  dateTime = [date, time]
    .join("T")
    .replace(" ", "")
    .replace(/\u200e/g, "");
  return dateTime;
};

export const getTimeFormatedForEI = dateObj => {
  let dateTime = new Date(dateObj).toLocaleDateString("en-GB", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });
  let date = dateTime
    .split(" ")[0]
    .split("/")
    .reverse()
    .join("-");
  let time = dateTime.split(" ")[1];
  dateTime = [date, time].join("T").replace(" ", "");

  return dateTime;
};

export function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name, value, options) {
  options = options || {};

  var expires = options.expires;

  if (typeof expires == "number" && expires) {
    var d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  var updatedCookie = name + "=" + value;

  for (var propName in options) {
    updatedCookie += "; " + propName;
    var propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += "=" + propValue;
    }
  }

  document.cookie = updatedCookie;
}

export const toRusFormat = time => {
  return (
    time
      .split("T")[0]
      .split("-")
      .reverse()
      .join("-") +
    " " +
    time
      .split("T")[1]
      .split("-")
      .join("-")
  );
};

export const createSendOrderJson = (
  key,
  userId,
  projectId,
  projectName,
  authKey,
  login,
  authTime,
  senderName,
  senderAddress,
  senderCopyAddress,
  senderContacts,
  sendTimeFrom,
  receiverName,
  receiveTimeFrom,
  receiverAddress,
  receiverCopyAddress,
  receiverContacts,
  cargos,
  description,
  currency,
  value,
  autoRef,
  autoRefTemp,
  thermoCheckAutoRef,
  clientNum,
  sendTimeTo,
  receiveTimeTo,
  hour,
  autoValue
) => {
  let orderJson = {};
  orderJson.server = "https://storedev.biocard.com";
  orderJson.page = "react_order_add";
  orderJson.event = "send";
  orderJson.user_uuid = userId; //this.props.auth.iduser1c;
  orderJson.project_uuid = projectId; //this.props.projects[this.props.project];
  orderJson.prjname = projectName; //this.props.project;
  orderJson.session_lead_key = key;
  orderJson.session_auth_key = authKey; //this.props.auth.session_key;
  orderJson.login = login; //this.props.auth.login;
  orderJson.auth_time = authTime; //this.props.auth.session_auth_time_start;
  orderJson.json = {};
  orderJson.json.one_form_ver = "3";
  orderJson.json.orders = [];

  const order = {};
  order.shipment = clientNum; //shipment
  order.user_uuid = userId; //this.props.auth.iduser1c;
  order.project_uuid = projectId; //this.props.projects[this.props.project];
  order.session_lead_key = key;
  order.sender = {};
  order.sender.type = "company";
  order.sender.name = senderName; //this.props.senderName;
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    order.sender.time_from = getTimeFormatedForEI(sendTimeFrom); //this.state.sendTimeFrom
    order.sender.time_to = getTimeFormatedForEI(sendTimeTo);
  } else {
    order.sender.time_from = getTimeFormated(sendTimeFrom); //this.state.sendTimeFrom
    order.sender.time_to = getTimeFormated(sendTimeTo);
  }
  
  order.sender.address =
    Object.keys(senderAddress).length > 0 //this.props.senderFullAddress
      ? senderAddress //this.props.senderFullAddress
      : senderCopyAddress; // this.props.senderCopyAddress;
  // order.sender.description = this.state.senderComments ? this.state.senderComments : null;
  order.sender.contacts = senderContacts.map(el => {
    //this.props.senderContacts
    return {
      name: el.name,
      phones: [el.number_phone]
    };
  });

  order.reciever = {};
  order.reciever.type = "company";
  order.reciever.name = receiverName; // this.props.receiverName
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    order.reciever.time_from = getTimeFormatedForEI(receiveTimeFrom); //this.state.receiveTimeFrom)
    order.reciever.time_to = getTimeFormatedForEI(receiveTimeTo);
  } else {
    order.reciever.time_from = getTimeFormated(receiveTimeFrom); //this.state.receiveTimeFrom)
    order.reciever.time_to = getTimeFormated(receiveTimeTo);
  }
  
  order.reciever.address =
    Object.keys(receiverAddress).length > 0 // if copy or fill new this.props.receiverFullAddress
      ? receiverAddress
      : receiverCopyAddress; // this.props.recevierCopyAddress;
  // order.reciever.description = this.state.receiverComments ? this.state.receiverComments : null;
  order.reciever.contacts = receiverContacts.map(el => {
    //this.props.receiverContacts
    return {
      name: el.name,
      phones: [el.number_phone]
    };
  });
  let autoRefRow;
  order.cargos = [];
  if (autoRef) {
    // if order delivered in autorefrigerator
    orderJson.json.cargo_type_list_typeCargo =
      "cb921237-91eb-11e6-8110-005056a77336";
    console.log(autoRefTemp);
    autoRefRow = {
      name: "авторефрижератор",
      value: 0,
      height: 0,
      lenght: 0,
      width: 0,
      weight: 0,
      amonut: 1,
      thermobox: true,
      sensor: thermoCheckAutoRef,
      temperature_mode: temperatureMap[autoRefTemp],
      cargo_type_list_typeCargo: "cb921237-91eb-11e6-8110-005056a77336",
      autoValue // value auto provided
    };
    order.cargos.push(autoRefRow);
  } else if (!autoRef && autoValue !== "Не важно") {
    // if order delivered in regular auto with value provided
    orderJson.json.cargo_type_list_typeCargo =
      "68f26e92-ce3c-11e9-81f5-ac1f6b00d3ad";
    autoRefRow = {
      name: "Автомобиль Грузовой",
      value: 0,
      height: 0,
      lenght: 0,
      width: 0,
      weight: 0,
      amonut: 1,
      thermobox: true,
      sensor: false,
      temperature_mode: temperatureMap["без температуры"],
      cargo_type_list_typeCargo: "68f26e92-ce3c-11e9-81f5-ac1f6b00d3ad",
      autoValue // value auto provided
    };
    order.cargos.push(autoRefRow);
  }
  cargos = cargos.map(el => {
    if (!el) return;
    return {
      name: el.cargoName,
      value: el.cost,
      height: el.height,
      lenght: el.length,
      width: el.width,
      weight: el.weight,
      amonut: el.quantity,
      thermobox: el.thermoBox,
      sensor: el.thermoCheck,
      temperature_mode: temperatureMap[el.temperature],
      cargo_type_list_typeCargo: el.cargo_type_list_typeCargo
      // smart_sn: 355856051467427
    };
  });
  order.cargos.push(...cargos);

  order.description = description; // this.props.finalComments;
  order.currency = currency; //this.props.currency;
  if (typeof value === "string") {
    value = value.replace(/ /g, "");
  }
  order.total_assessed_value = value; //this.props.value;

  orderJson.json.orders.push(order);

  console.log(orderJson);

  return orderJson;
};

export const importDataParse = data => {
  let dataForTable = data.map(el => [
    el.data.orders[0].sender.date,
    el.data.orders[0].sender.time_from,
    el.data.orders[0].sender.time_to,
    el.data.orders[0].sender.contacts.map(el => el.name).join(", "),
    el.data.orders[0].sender.address.full,
    el.data.orders[0].sender.contacts.map(el => el.phones[0]).join(", "),
    el.data.orders[0].reciever.date,
    el.data.orders[0].reciever.time_from,
    el.data.orders[0].reciever.time_to,
    el.data.orders[0].reciever.name,
    el.data.orders[0].reciever.contacts.map(el => el.name).join(", "),
    el.data.orders[0].reciever.address.full,
    el.data.orders[0].reciever.contacts.map(el => el.phones[0]).join(", "),
    el.data.orders[0].cargos[0].name,
    el.data.orders[0].cargos[0].amonut,
    el.data.orders[0].cargos[0].sensor,
    el.data.orders[0].cargos[0].temperature_mode,
    el.data.orders[0].total_assessed_value,
    el.data.orders[0].description,
    el.data.orders[0].shipment,
    el.data.orders[0].cargos[0].weight
  ]);

  let errors = data.map((el, i) => {
    let result = [];
    if (!el.verify.orders[0].sender.date) result.push([0, i]);
    if (!el.verify.orders[0].sender.time_from) result.push([1, i]);
    if (!el.verify.orders[0].sender.time_to) result.push([2, i]);
    if (!el.verify.orders[0].sender.contacts.name) result.push([3, i]);
    if (!el.verify.orders[0].sender.address) result.push([4, i]);
    if (!el.verify.orders[0].sender.contacts.phones) result.push([5, i]);
    if (!el.verify.orders[0].reciever.date) result.push([6, i]);
    if (!el.verify.orders[0].reciever.time_from) result.push([7, i]);
    if (!el.verify.orders[0].reciever.time_to) result.push([8, i]);
    // 'Компания получателя',
    if (!el.verify.orders[0].reciever.contacts.name) result.push([10, i]);
    if (!el.verify.orders[0].reciever.address) result.push([11, i]);
    if (!el.verify.orders[0].reciever.contacts.phones) result.push([12, i]);
    // 'Тип контейнера',
    // 'Количество',
    // 'Термодатчик',
    // 'Температурный режим',
    if (!el.verify.orders[0].total_assessed_value) result.push([17, i]); // 'Оценочная стоимость',
    // 'Комментарий',
    // 'Номер поставки'
    if (!el.verify.orders[0].cargos[0].weight) result.push([20, i]);
    return result;
  });

  return { errors, dataForTable };
};

export const importDataParseExtended = data => {
  let dataForTable = data.map((el, i) => [
    i + 1,
    el.data.orders[0].shipment,
    el.data.orders[0].sender.date,
    el.data.orders[0].sender.time_from,
    el.data.orders[0].sender.time_to,
    el.data.orders[0].sender.name,
    el.data.orders[0].sender.contacts.map(el => el.name).join(", "),
    el.data.orders[0].to_description_only_view.to_description_sender_city,
    el.data.orders[0].sender.address.full,
    el.data.orders[0].sender.contacts.map(el => el.phones).join(", "), //phone
    el.data.orders[0].reciever.date,
    el.data.orders[0].reciever.time_from,
    el.data.orders[0].reciever.time_to,
    el.data.orders[0].reciever.name,
    el.data.orders[0].reciever.contacts.map(el => el.name).join(", "),
    el.data.orders[0].to_description_only_view.to_description_reciever_city,
    el.data.orders[0].reciever.address.full,
    el.data.orders[0].reciever.contacts.map(el => el.phones).join(", "), //phone
    el.data.orders[0].to_description_only_view.to_description_shipment_type,
    el.data.orders[0].cargos[0].temperature_mode,
    el.data.orders[0].to_description_only_view.to_description_need_return,
    el.data.orders[0].cargos[0].weight,
    el.data.orders[0].cargos[0].amonut,
    el.data.orders[0].total_assessed_value,
    el.data.orders[0].cargos[0].name.indexOf('SafePack') === -1 ? `SafePack ${el.data.orders[0].cargos[0].name}` : el.data.orders[0].cargos[0].name,
    el.data.orders[0].to_description_only_view.to_description_commission,
    el.data.orders[0].to_description_only_view.to_description_department,
    el.data.orders[0].to_description_only_view
      .to_description_equipment_identification,
    el.data.orders[0].to_description_only_view.to_description_sensor_amount,
    el.data.orders[0].description
  ]);

  let errors = data.map((el, i) => {
    let result = [];
    // result.push([0, i]);
    // result.push([1, i]);
    if (!el.verify.orders[0].sender.date) result.push([2, i]);
    if (!el.verify.orders[0].sender.time_from) result.push([3, i]);
    if (!el.verify.orders[0].sender.time_to) result.push([4, i]);
    if (!el.verify.orders[0].sender.name) result.push([5, i]);
    if (!el.verify.orders[0].sender.contacts.name) result.push([6, i]);
    if (!el.verify.orders[0].sender.address) result.push([7, i]);
    if (!el.verify.orders[0].sender.address) result.push([8, i]);
    if (!el.verify.orders[0].sender.contacts.phones) result.push([9, i]);
    if (!el.verify.orders[0].reciever.date) result.push([10, i]);
    if (!el.verify.orders[0].reciever.time_from) result.push([11, i]);
    if (!el.verify.orders[0].reciever.time_to) result.push([12, i]);
    if (!el.verify.orders[0].reciever.name) result.push([13, i]);
    if (!el.verify.orders[0].reciever.contacts.name) result.push([14, i]);
    // if (!el.verify.orders[0].reciever.address) result.push([15, i]);
    if (!el.verify.orders[0].reciever.address) result.push([16, i]);
    if (!el.verify.orders[0].reciever.contacts.phones) result.push([17, i]);
    //'тип отправления',
    if (!el.verify.orders[0].cargos[0].temperature_mode) result.push([19, i]);
    // "необходимость возврата",
    if (!el.verify.orders[0].cargos[0].weight) result.push([21, i]);
    // "количество термобоксов",
    if (!el.verify.orders[0].total_assessed_value) result.push([23, i]);
    // "вложения",
    // "поручения",
    // "отдел",
    // "датчик",
    // "количество датчиков",
    // комментарий
    // if (!el.data.orders[0].description) result.push([29, i]);
    return result;
  });

  return { errors, dataForTable };
};

export const importCheckDataParse = data => {
  let result = {};
  result.data = [];
  data.forEach(el => {
    result.data.push({
      sender_date: el[0],
      sender_time_from: el[1],
      sender_time_to: el[2],
      sender_contact_name: el[3],
      sender_address: el[4],
      sender_phone: el[5],
      reciever_date: el[6],
      reciever_time_from: el[7],
      reciever_time_to: el[8],
      reciever_company_name: el[9],
      reciever_contact_name: el[10],
      reciever_address: el[11],
      reciever_phone: el[12],
      cargo_name: el[13],
      cargo_amonut: el[14],
      cargo_sensor: el[15],
      cargo_temperature_mode: el[16],
      total_assessed_value: el[17],
      description: el[18],
      shipment: el[19],
      weight: el[20]
    });
    return result;
  });
  return result;
};

export const importCheckDataParseExt = data => {
  let result = {};
  result.data = [];
  data.forEach(el => {
    result.data.push({
      sender_date: el[2],
      sender_company_name: el[5],
      sender_time_from: el[3],
      sender_time_to: el[4],
      sender_contact_name: el[6],
      sender_address: el[8],
      sender_phone: el[9],
      reciever_date: el[10],
      reciever_time_from: el[11],
      reciever_time_to: el[12],
      reciever_company_name: el[13],
      reciever_contact_name: el[14],
      reciever_address: el[16],
      reciever_phone: el[17],
      cargo_name: el[24],
      cargo_amonut: el[22],
      cargo_sensor: el[27] ? true : false,
      cargo_temperature_mode: el[19],
      total_assessed_value: el[23],
      description: el[29],
      shipment: el[1],
      weight: el[21],
      to_description_only_view: {
        to_description_commission: el[25],
        to_description_department: el[26],
        to_description_equipment_identification: el[27],
        to_description_need_return: el[20],
        to_description_number: el[22],
        to_description_reciever_city: el[15],
        to_description_sender_city: el[7],
        to_description_sensor_amount: el[28],
        to_description_shipment_type: el[18]
      }
    });
    return result;
  });
  return result;
};

export const toFilterDate = d => {
  return (
    d.getFullYear().toString() +
    "-" +
    ((d.getMonth() + 1).toString().length == 2
      ? (d.getMonth() + 1).toString()
      : "0" + (d.getMonth() + 1).toString()) +
    "-" +
    (d.getDate().toString().length == 2
      ? d.getDate().toString()
      : "0" + d.getDate().toString()) +
    " " +
    (d.getHours().toString().length == 2
      ? d.getHours().toString()
      : "0" + d.getHours().toString()) +
    ":" +
    ((parseInt(d.getMinutes() / 5) * 5).toString().length == 2
      ? (parseInt(d.getMinutes() / 5) * 5).toString()
      : "0" + (parseInt(d.getMinutes() / 5) * 5).toString()) +
    ":00"
  );
};
