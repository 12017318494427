import axios from "../axios";
import { takeLatest, call, put, throttle } from "redux-saga/effects";

export const moduleName = "regulatory";

export const REQUEST_ALL_PROJECTS = moduleName + "/REQUEST_ALL_PROJECTS";
export const RECEIVE_ALL_PROJECTS = moduleName + "/RECEIVE_ALL_PROJECTS";

export const ERROR_ALL = moduleName + "/ERROR_ALL";

export const RECEIVE_ONE_PROJECT = moduleName + "/RECEIVE_ONE_PROJECT";

export const REQUEST_ALL_ORDERS = moduleName + "/REQUEST_ALL_ORDERS";
export const RECEIVE_ALL_ORDERS = moduleName + "/RECEIVE_ALL_ORDERS";

export const REQUEST_ONE_ORDER = moduleName + "/REQUEST_ONE_ORDER";
export const RECEIVE_ONE_ORDER = moduleName + "/RECEIVE_ONE_ORDER";

export const CLEAR_ORDER = moduleName + "/CLEAR_ORDER";

/*                                            ACTIONS                                            */

export function requestOrdersProjects(userId) {
  return { type: REQUEST_ALL_PROJECTS, payload: userId };
}

export function receiveProjects(projects) {
  return { type: RECEIVE_ALL_PROJECTS, payload: projects };
}

export function receiveError(error) {
  return { type: ERROR_ALL, payload: error };
}

export function requestOrders(
  userId,
  project,
  filter,
  search,
  dateFrom,
  dateTo
) {
  return {
    type: REQUEST_ALL_ORDERS,
    payload: { userId, project, filter, search, dateFrom, dateTo }
  };
}

export function receiveOrders(orders = {}) {
  return { type: RECEIVE_ALL_ORDERS, payload: orders };
}

/*                                           SAGAS                                           */

export const getProjectsCustoms = async userId => {
  const data = {
    client_erpId: "1f82df7b-0206-11e6-80de-00155d006303"
  };
  return await axios.post("customs/prj01.php", data, {});
};

function* projectsEffectSaga(action) {
  try {
    let { data } = yield call(getProjectsCustoms, action.payload);
    data = data.data.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.prjname]: cur.prj_id
      };
    }, {});
    // localStorage.setItem("projects", JSON.stringify(data));
    yield put(receiveProjects(data));
  } catch (e) {
    console.error(e);
    receiveError(e);
  }
}

export function* projectsOrdersWatcherSaga() {
  yield takeLatest(REQUEST_ALL_PROJECTS, projectsEffectSaga);
}

export const getOrders = async ({
  userId,
  project,
  search,
  dateFrom,
  dateTo
}) => {
  const data = {
    client_erpId: userId,
    Proekt: project,
    date_from: dateFrom,
    date_to: dateTo,
    search,
  };
  return await axios.post("/regulatory/list03.php", data);
};

function* ordersEffectSaga(action) {
  try {
    let { data } = yield call(getOrders, action.payload);
    yield put(receiveOrders(data));
  } catch (e) {
    console.error(e);
    receiveError(e);
  }
}

/*                                            ACTIONS                                            */

export function* ordersWatcherSaga() {
  yield throttle(700, REQUEST_ALL_ORDERS, ordersEffectSaga);
}

export function requestOneOrder(iduser1c, docid) {
  return { type: REQUEST_ONE_ORDER, payload: { iduser1c, docid } };
}

export const receiveOneOrder = order => {
  return { type: RECEIVE_ONE_ORDER, payload: order };
};


export const receiveOneProject = project => {
  return { type: RECEIVE_ONE_PROJECT, payload: project };
};

export const getOneOrder = async ({iduser1c, docid}) => {
  let body = { docid, iduser1c };
  console.log(body);
  return await axios.post("/customs/detail01.php", body, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

/*                                           SAGAS                                           */

function* oneOrderEffectSaga(action) {
  try {
    let { data } = yield call(getOneOrder, action.payload);
    yield put(receiveOneOrder(data));
  } catch (e) {
    console.error(e);
    receiveError(e);
  }
}

export function* oneOrderWatcherSaga() {
  yield takeLatest(REQUEST_ONE_ORDER, oneOrderEffectSaga);
}

export const initialState = {
  projects: null,
  project: "",
  orders: [],
  order: null,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ALL_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false
      };
    case RECEIVE_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false
      };
    case RECEIVE_ONE_PROJECT:
      return {
        ...state,
        project: action.payload
      };
    case RECEIVE_ONE_ORDER:
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload
        },
        loading: false
      };
    case REQUEST_ONE_ORDER:
      return {
        ...state,
        loading: true
      };
    case REQUEST_ALL_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_ALL_PROJECTS:
      return {
        ...state,
        loading: true
      };
    case ERROR_ALL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
