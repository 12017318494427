import axios from '../axios';
import { takeLatest, call, put, throttle } from 'redux-saga/effects';

export const moduleName = 'orderCreate';

export const REQUEST_ADDRESS_CHOICE = moduleName + '/REQUEST_ADDRESS_CHOICE';
export const RECEIVE_ADDRESS_CHOICE = moduleName + '/RECEIVE_ADDRESS_CHOICE';
export const REQUEST_ADDRESS = moduleName + '/REQUEST_ADDRESS';
export const RECEIVE_ADDRESS = moduleName + '/RECEIVE_ADDRESS';
export const GET_DELIVERY_SENDER = moduleName + '/GET_DELIVERY_SENDER';
export const GET_DELIVERY_RECEIVER = moduleName + '/GET_DELIVERY_RECEIVER';

/*                          ACTIONS                                    */

export function requestAddress(inputValue, iduser1c, type) {
	return {
		type: REQUEST_ADDRESS_CHOICE,
		payload: { inputValue, iduser1c, type }
	};
}

export function receiveAddress(address, fullAddress) {
	return { type: RECEIVE_ADDRESS_CHOICE, payload: { address, fullAddress } };
}

export function requestDadataAddress(address, type) {
	return { type: REQUEST_ADDRESS, payload: { data: address, type: type } };
}

export function receiveFullAddress(address, type) {
	return { type: RECEIVE_ADDRESS, payload: { address, type } };
}

/*                                           SAGAS                                           */

export const getAddress = async (inputValue, iduser1c) => {
	let formData = new FormData();
	formData.append('key', '1b317c56-33de-4e0b-9b65-f1b4a08b1b3a');
	formData.append('proekt_id', 'ab1b7248-fa8f-11e7-80d4-ac1f6b00d3ad');
	formData.append('project_uuid', 'ab1b7248-fa8f-11e7-80d4-ac1f6b00d3ad');
	formData.append('partner_type', 'is_sender');
	formData.append('search_address', inputValue);
	formData.append('iduser1c', iduser1c);
	formData.append('session_auth_key', 'test');
	formData.append('session_lead_key', 'test');
	formData.append('in_type', 'dadata');
	return await axios.post('/book/dadata_prod_03.php', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

function* addressesEffectSaga(action) {
	try {
		let { data } = yield call(getAddress, action.payload.inputValue, action.payload.iduser1c, action.payload.type);
		const address = data.out.address_name ? data.out.address_name : [];
		const fullAddress = data.out.address_name.for_1cjson_address;
		yield put(receiveAddress(address, fullAddress));
		if (action.payload.type) {
			yield put(receiveFullAddress(data.out.address_name[0].for_1cjson_address, action.payload.type));
		}
	} catch (e) {
		console.error(e);
	}
}

export function* addressesWatcherSaga() {
	yield throttle(1000, REQUEST_ADDRESS_CHOICE, addressesEffectSaga);
}

/*                          ACTIONS                                    */

export const ADD_CARGO = moduleName + '/ADD_CARGO';
export const DELETE_CARGO = moduleName + '/DELETE_CARGO';
export const COPY_CARGOS = moduleName + '/COPY_CARGOS';
// export const CHOOSE_CARGO = moduleName + "/CHOOSE_CARGO";
export const EDIT_CARGO = moduleName + '/EDIT_CARGO';
export const AUTO_REF = moduleName + '/AUTO_REF';
export const AUTO_CHOOSE_ERROR = moduleName + '/AUTO_CHOOSE_ERROR';

export function addCargo(cargo) {
	return { type: ADD_CARGO, payload: cargo };
}

// export function chooseCargo(cargo) {
//   return { type: CHOOSE_CARGO, payload: cargo };
// }

export function deleteCargo(cargoId) {
	return { type: DELETE_CARGO, payload: cargoId };
}

export function copyCargos(cargos) {
	return { type: COPY_CARGOS, payload: cargos };
}

export function addAutoRef(sign) {
	return { type: AUTO_REF, payload: sign };
}

export function setAutoChooseError(error) {
	return { type: AUTO_CHOOSE_ERROR, payload: error };
}

export const ADD_CONTACT = moduleName + '/ADD_CONTACT';
export const DELETE_CONTACT = moduleName + '/DELETE_CONTACT';
export const COPY_CONTACTS = moduleName + '/COPY_CONTACTS';
export const CLEAR_CONTACTS = moduleName + '/CLEAR_CONTACTS';

export function addContact(type, contact) {
	return { type: ADD_CONTACT, payload: { type, contact } };
}

export function deleteContact(type, contactId) {
	return { type: DELETE_CONTACT, payload: { type, contactId } };
}

export function copyContacts(type, contacts) {
	return { type: COPY_CONTACTS, payload: { type, contacts } };
}

export function clearContacts() {
	return { type: CLEAR_CONTACTS };
}

/*                                           SAGAS                                           */

export const SET_SNACKBAR = moduleName + '_SET_SNACKBAR';

export function setSnackBar(snackBar) {
	return { type: SET_SNACKBAR, payload: snackBar };
}

export const initialState = {
	address: [],
	senderFullAddress: {},
	receiverFullAddress: {},
	snackBar: null,
	deliveryOrder: {
		senderAddress: '',
		senderName: '',
		senderContacts: [],
		receiverAddress: '',
		receiverName: '',
		receiverContacts: [],
		cargos: []
	},
	autoChooseError: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_ADDRESS_CHOICE:
			return {
				...state,
				address: [ ...action.payload.address ]
			};
		case RECEIVE_ADDRESS: {
			const type = action.payload.type + 'FullAddress';
			return {
				...state,
				[type]: {
					...state[type],
					...action.payload.address
				}
			};
		}
		case ADD_CARGO:
			let cargos = [ ...state.deliveryOrder.cargos ];
			cargos.push(action.payload);
			return {
				...state,
				deliveryOrder: {
					...state.deliveryOrder,
					cargos
				}
			};
		case DELETE_CARGO:
			let deletedCargos = [ ...state.deliveryOrder.cargos ];
			deletedCargos.splice(action.payload, 1);
			return {
				...state,
				deliveryOrder: {
					...state.deliveryOrder,
					cargos: deletedCargos
				}
			};
		case COPY_CARGOS:
			return {
				...state,
				deliveryOrder: {
					...state.deliveryOrder,
					cargos: action.payload
				}
			};
		case AUTO_REF:
			return {
				...state,
				autoRef: action.payload
			};
		case ADD_CONTACT: {
			const type = action.payload.type + 'Contacts';
			let contacts = [ ...state.deliveryOrder[type] ];
			contacts.push(action.payload.contact);
			return {
				...state,
				deliveryOrder: {
					...state.deliveryOrder,
					[type]: contacts
				}
			};
		}
		case DELETE_CONTACT: {
			const type = action.payload.type + 'Contacts';
			let deletedContacts = [ ...state.deliveryOrder[type] ];
			deletedContacts.splice(action.payload.contactId, 1);
			return {
				...state,
				deliveryOrder: {
					...state.deliveryOrder,
					[type]: deletedContacts
				}
			};
		}
		case COPY_CONTACTS:
			const type = action.payload.type + 'Contacts';
			let copiedContacts = [ ...action.payload.contacts ];
			return {
				...state,
				deliveryOrder: {
					...state.deliveryOrder,
					[type]: copiedContacts
				}
			};
		case SET_SNACKBAR:
			return {
				...state,
				snackBar: action.payload
			};
		case AUTO_CHOOSE_ERROR:
			return {
				...state,
				autoChooseError: action.payload
			};
		case CLEAR_CONTACTS:
			return initialState;
		case '@redux-form/CHANGE':
			return initialState;
		default:
			return state;
	}
};

export default reducer;
