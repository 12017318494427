import React from "react";
import PropTypes from "prop-types";
//material components
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import StoreIcon from "@material-ui/icons/Store";
import BorderIcon from "@material-ui/icons/Language";
import OrderIcon from "@material-ui/icons/LocalShipping";
import ImportIcon from "@material-ui/icons/ImportExport";
import QuestionIcon from "@material-ui/icons/HelpOutlined";
import RegulIcon from "@material-ui/icons/ChromeReaderMode";
import ListOrders from "@material-ui/icons/ListAltOutlined";
import PurchaseIcon from "@material-ui/icons/ShoppingBasket";
//router
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//styles
import styles from "./sideDrawerStyles";
// hooks
import { useTranslation } from "react-i18next";
import { instructionHook } from "./instructions/instructionHook";
import Instructions from "./instructions/inscructionCard";

import data from "./instructions/data";

const sideDrawer = React.memo(props => {
  const { t } = useTranslation();
  const { classes, open } = props;
  let links = {
    transportation: "/transportation",
    store: "/store/order",
    customs: "/customs/history",
    regulatory: "/regul/history",
    purchasing: "/purchasing/history"
  };
  const setOpenHelp =
    localStorage.getItem("openInstructions") === "" ? false : true;
  let { openHelp, handleOpenInstructions, setPage, page } = instructionHook(
    setOpenHelp
  );
  const { pathname } = props.location;

  let subLinks = { import: "/import" };
  if (pathname.includes("/store")) {
    subLinks = {};
  }
  if (pathname === "/customs/create" || pathname === "/customs/history") {
    subLinks = { history: "/customs/history" };
  }
  if (pathname === "/regul/history" || pathname === "/regul/create") {
    subLinks = { history: "/regul/history" };
  }

  return (
    <React.Fragment>
      <Instructions
        page={page}
        setPage={setPage}
        data={data[pathname.split("/")[1]]}
        openHelp={openHelp}
        setOpen={handleOpenInstructions}
      />
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar} />
        <List>
          {Object.keys(links).map(key => {
            let icon;
            switch (key) {
              case "store":
                icon = <StoreIcon />;
                break;
              case "customs":
                icon = <BorderIcon />;
                break;
              case "Мои заказы":
                icon = <ListOrders />;
                break;
              case "regulatory":
                icon = <RegulIcon />;
                break;
              case "purchasing":
                icon = <PurchaseIcon />;
                break;
              default:
                icon = <OrderIcon />;
                break;
            }
            return (
              <Link
                to={links[key]}
                style={{ textDecoration: "none", color: "black" }}
                key={key}
              >
                <ListItem
                  button
                  key={key}
                  selected={window.location.pathname.includes(links[key])}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={t(key)} />
                </ListItem>
              </Link>
            );
          })}
        </List>
        <Divider />
        <List>
          {Object.keys(subLinks).map((key, index) => (
            <Link
              to={subLinks[key]}
              style={{ textDecoration: "none", color: "black" }}
              key={key}
            >
              <ListItem
                button
                selected={subLinks[key] === window.location.pathname}
              >
                <ListItemIcon>
                  {index % 2 === 0 ? <ImportIcon /> : <ListOrders />}
                </ListItemIcon>
                <ListItemText primary={t(key)} />
              </ListItem>
            </Link>
          ))}
          <ListItem
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenInstructions(!openHelp)}
          >
            <ListItemIcon>
              <QuestionIcon style={{ color: !openHelp ? "#660000" : "gray" }} />
            </ListItemIcon>
            <ListItemText
              style={{ color: !openHelp ? "#660000" : "gray" }}
              primary={t('tips')}
            />
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
});

sideDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(sideDrawer));
