import rootSaga from './sagas';
import reducer from './reducers';
import history from './history';

import createSagaMiddleware from 'redux-saga';
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';

let composeEnhancers;
if (process.env.REACT_APP_API_HOSTNAME === 'my.biocard.com') {
	composeEnhancers = compose;
} else {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const sagaMiddleware = createSagaMiddleware();

let enhancer;

enhancer = applyMiddleware(sagaMiddleware, routerMiddleware(history));

const store = createStore(reducer(history), composeEnhancers(enhancer));
window.store = store;

sagaMiddleware.run(rootSaga);

export default store;
