import backgroundImage from "./assets/bg-2.jpg";

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    backgroundImage: `url(${backgroundImage})`
  },
  backgroundRepeat: "repeat",
  content: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(1),
    marginTop: theme.spacing(8),
    justifyContent: "center"
  }
});

export default styles;
