const data = {
  "en":{
     "translation":{
        "Delivery Date can not be less than that available":" Delivery Date can not be less than that available ",
        "You should add contact persons by chicking on + button":" You should add contact persons by clicking on + button ",
        "Total gross weight":"Total gross weight",
        "You'll be provided with dry ice. Please chose container without temperature if you have your own dry ice ":"You'll be provided with dry ice. Please chose container without temperature if you have your own dry ice",
        "Cargo will be delivered in Auto-refrigirator wuth controlled temperature ":" Cargo will be delivered in Auto-refrigirator wuth controlled temperature ",
        "Cargo will be delivered in separate vehicle":"Cargo will be delivered in separate vehicle ",
        "Please add cargo information":"Please add cargo information",
        "Please spend 1 minute to check Hints. It will help you to work with online service. Hints are different on every page.": " Please spend 1 minute to check Hints. It will help you to work with online service. Hints are different on every page.",
        "Your cargo is too large, chose Auto-refregirator for delivery":" Your cargo is too large, chose Auto-refregirator for delivery ",
        "НОВЫЙ":"NEW",
        "В РАБОТЕ":"IN PROGRESS",
        "В работе":"In progress",
        "ВЫПОЛНЕН":"COMPLETED",
        "ОТГРУЖЕН":"DISPATCHED",
        "В случае необходимости уточнения подробностей, менеджер свяжется с Вами.":"Manager will contact you if you have any questions",
        "Заявка обработана":"Request Processed",
        "Менеджер проверил Вашу заявку":"Manager checked your request",
        "СОЗДАНЫ ЗАДАНИЯ":"Planned Route",
        "Документы на груз подготовлены":"Prepared Documents",
        "Груз вручен получателю. Спасибо за Ваш выбор":"Delivered to consignee",
        "ГРУЗ ДОСТАВЛЕН":"Delivered",
        "Получена отметка о контрольной точке маршрута": "Route checkpoint received",
        "КУРЬЕР ПРИНЯЛ ГРУЗ В РЕГИОНЕ ПОЛУЧАТЕЛЯ":"Courier received cargo in delivery region",
        "Груз получен курьером":"Cargo received by Courier",
        "НАХОДИТСЯ НА ДОСТАВКЕ У КУРЬЕРА":"Courier is on the way",
        "НАХОДИТСЯ НА ДОСТАВКЕ": "On delivery",
        "В ближайшее время груз будет доставлен":"Cargo will be delivered in shortest terms",
        "Отгружено с таможни":"Dispatched from customs",
        "Подтверждена":"Confirmed",
        "Подготовка ДТ":"Preparing Customs Declaration",
        "Готова ДТ":"Prepared Customs Declaration",
        "Зарегистрирована ДТ":"Registered Customs Declaration",
        "Выпущена ДТ":"Completed Customs Declaration",
        "Запрос получен": "Request received",
        "Ожидание оплаты": "Waiting for payment",
        "Заказ выполнен": "Order completed",
        "Не важно": "Any",
        "Общий вес Брутто": "Gross weight",
      "Коммерческое предложение отправлено": "Commercial Offer is sent",
      "Заказ подтвержден": "Order confirmed",
      "Закупка инициирована": "Purchase is initiated",
      "Ожидание поступления оплаты": "Waiting for payment",
      "Оплата получена": "Payment is received",
      "Заказ поставщику сформирован": "Order to supplier created",
      "Ожидание поступления товара": "Waiting for inbound shipment",
      "Заказ выполнен, товар на складе": "Order complete, cargo is at warehouse",
      "Запрос документов": "Request for documents",
      "Подготовка документов": "Documents preparing",
      "Согласование": "Documents approval",
      "Подготовка заявления в государственный орган": "Preparing of request to a government agency",
      "Подача документов в государственный орган": "Sending documents  to a government agency",
      "Ожидание решения": "Waiting for approval",
      "Получен разрешительный документ": "Approved documents are received",
      "без температуры": "no temperature",
      "ждет присвоения": "waiting for assignment",
      "температура авторефрижератора": "refrigerator truck temperature",
      "Дата по убыванию": "Sort by date descending",
      "Дата по возрастанию": "Sort by date ascending",
      "Детальня страница заявки": "Order details page",
      "Номер заказа по убыванию": "Sort by number descending",
      "Номер заказа по возрастанию": "Sort by number ascending",
      "ЗАЯВКА ПРИНЯТА":"Request received",
      "ЗАЯВКА ОБРАБОТАНА":"Request Processed",
      "ОТМЕНЕНА": "Canceled",
      "Вы можете оставить нам отзыв нажав на иконку. Все отзывы будут рассмотрены, и приняты меры по улучшению работы личного кабинета."  :"You can leave a feedback by clicking an icon. We’ll receive your feedback and perform actions to improve the dashboard.",
"Вы можете скопировать заявку нажав на иконку. Новая заявка будет содержать все скопированные данные, останется только добавить необходимые изменения, и отправить.": "You can copy an order by clicking an icon. New order will have the same copied data and you’ll just need to make some corrections if needed and send an order.",
"Обратите внимание на подсказки при вводе адреса, они помогут вам сэкономить время заполнения заявки": "Notice hints when adding Address, they will help you to save your time",
"Минимальные даты отправления и получения будут рассчитаны автоматически исходя из адресов в заявке" : "Earliest dispatch and delivery dates will be counted automatically according to addresses in request",
"У вас есть возможность заказать отдельный автотранспорт указанной тонажности, в меню справа, без добавления грузовых мест в заявку" : "You have an opportunity to order a separate transport without adding cargo pieces in menu on the right",
"Страница создания заявки, контакты": "Create order page, contacts", 
"Страница создания заявки, грузы": "Create order page, cargos",
        "Коробка": "Box",
        "На отгрузке":"In a shipment",
        "all":"Show all",
        "available":"Available",
        "and":"and",
        "address":"Address",
        "air":"Shipments by Air",
        "add to order":"Add to order",
        "approved":"Data is correct",
        "approve your order":"Approve your order",
        "autoref":"Autorefrigerator",
        "bad":"Bad",
        "brut":"Gross weight",
        "buy":"Buy",
        "back":"back",
        "box":"Box",
        "Back if need":"Return necessity",
        "Cargo information":"Cargo information",
        "Cargo name":"Item",
        "cargo number not assigned":"Shipment number is not assigned",
        "Contact information":"Contact information",
        "Company sender":"Sender-Company",
        "Customs Form Title":"Customs clearance form",
        "Customs clearance":"Customs clearance",
        "cargo":"Cargo Dimensions",
        "carantine":"Quarantine",
        "cancel":"Cancel",
        "Cargo received by Courier":"Cargo received by Courier",
        "Courier received cargo in delivery region":"Courier received cargo in delivery region",
        "Cargo will be delivered in shortest terms":"Cargo will be delivered in shortest terms",
        "customs":"Customs",
        "customs order":"Customs Clearance",
        "cm":"cm",
        "copy":"Copy",
        "comment":"Comments:",
        "close":"Close",
        "choose":"Choose",
        "choosen cell":"Chosen element",
        "create order":"Сreate order",
        "choose box":"Choose your package",
        "choose proper temperature":"Choose proper temperature",
        "choose project":"Choose project",
        "choose weight and quantity":"Enter weight, quantity and cargo dimensions",
        "contact person":"Contact person",
        "check if you need thermobox or thermal sensor":"Tip if Thermobox and temperature logger are needed",
        "Chose necessary temperature for auto-refrigerator": " Chose necessary temperature for auto-refrigerator ",
"Mark if temperature logger if needed": "Mark if temperature logger if needed",
"Mark vehicle size if needed": "Mark vehicle size if needed",
"tons ": " tons ",

        "center number":"Site Number",
        "create store order":"Place and order for dispatch",
        "City sender":"Sender-City",
        "City recipient":"Consignee-City",
        "Courier is on the way":"Courier is on the way",
        "date & time":"Date and time",
        "date":"Date",
        "Delivered":"Delivered",
        "Delivered to consignee":" Delivered to consignee",
        "Thank you for your choice!":" Thank you for your choice!",
        "Dimensions":"Dimensions",
        "Delivery terms":"Shipment conditions",
        "Department":"Department",
        "Delivery type":"Delivery type",
        "delivery":"Add to order",
        "delivery docs":"Delivery document",
        "docs":"Documents",
        "no docs":"No uploaded docs",
        "done":"Completed",
        "dueTo":"Expiry Date",
        "download file":"Download file",
        "drop file":"Drop a file here or press for upload",
        "enter name manually":"Enter name manually",
        "ground":"Shipments by Auto",
        "great":"Great",
        "height":"Height",
        "kg":"kg",
        "length":"Length",
        "leave your feedback":"Leave your feedback",
        "logout":"Log out",
        "m3":"m3",
        "Manager will contact you if you have any questions":"Manager will contact you if you have any questions",
        "Manager checked your request":" Manager checked your request",
        "name":"Name",
        "net":"net weight",
        "new":"New",
        "num":"Number",
        "number not assigned":"Number not assigned yet",
        "no temperature":"No temperature control needed ",
        "no temp": "no temperature",
        "normal":"Normal",
        "no store projects":"You do not have any available projects. Please contact to manager to request an access.",
        "no orders":"No data available for that time period",
        "new order":"New Order",
        "order number":"Order number",
        "foreign economic activity":"Foreign economic activity consulting",
        "from":"From",
        "forward":"Next",
        "Final step":"Last step",
        "file":"File",
        "fix errors":"You need to correct mistakes highlighted in red",
        "history":"Orders history",
        "order":"Order",
        "order info":"Approve your order",
        "order status":"Order status",
        "order from":"Orders of",
        "total orders":"Total orders",
        "orders errors":"Orders with errors",
        "orders no errors":"Orders without errors",
        
        "total errors":"Total errors",
        "outside":"Outside",
        "or":"or",
        "phone":"Phone",
        "pcs":"pcs",
        "Package type":"Package type",
        "provided order num":"Provided order number",
        "project":"Project",
        "purchase order":"Purchase order",
        "purchasing":"Purchasing",
        "please type":"Start typing here",
        "Planned Route":" Planned Route",
        "Prepared Documents":" Prepared Documents",
        "Request received":"Request received",
        "Request Processed":"Request Processed",
        "Receiver address":"Consignee address",
        "Receiver name":"Consignee name",
        "regulatory":"Regulatory",
        "recipient":"Recipient",
        "refrigerator truck":"Use refrigerator truck",
        "refrigerator truck temperature":"Refrigerator truck temperature",
        "rating":"Please leave a feedback",
        "rub":"rub",
        "returned":"Returned",
        "import":"Orders uploading",
        "import instruction":"Orders uploading manual",
        "import subtitle":"This dashboard instrument will help you to upload shipment orders from an excel file",
        "import fill file":"Fill an excel file with necessary orders information. One order on each line.",
        "import file upload":"Upload your file into a form below",
        "import fix errors":"All the mistakes will be highlighted in red after uploading. You should fix all of them and try to upload file again",
        "import finish":"Send” button will appear Once your excel file will be validated and you will see an orders in orders list",
        "info":"Notes",
        "inside":"Inside",
        "in process":"In process",
        "Sender address":"Dispatch address",
        "Sender name":"Sender company name",
        "sender":"Sender",
        "sensor":"Temperature logger",
        "series":"Batch",
        "sensor autoref":"Report from temperature logger",
        "smart":"Smart temperature logger",
        "send":"Send",
        "send for errors fix":"Send for errors checking",
        "search":"Search..",
        "shipping":"Shipments by sea",
        "show":"Show",
        "store":"Warehouse",
        "stocks":"Warehouse Inventory",
        "stock":"Inventory",
        "start typing project":"Enter project name",
        "store order":"Shipment from warehouse",
        "show on map":"Show on map",
        "status change":"Status Change",
        "Total Items":"Total Items",
        "Copy Order":"Copy Order",
        "Leave a Feedback":"Leave a Feedback",
        "Download attached files":"Download attached files",
        "Use BIOCARD SafePack package":"Use BIOCARD SafePack package",
        "Use cardboard boxes without temperature control":"Use cardboard boxes without temperature control",
        "Provide a vehicle with temperature control":"Provide a vehicle with temperature control",
        "All cargo places will be delivered as a general cargo":"All cargo places will be delivered as a general cargo",
        "Total Cargo Places":"Total Cargo Places",
        "Internal Box Dimensions":"Internal Box Dimensions",
        "temperature":"Temperature",
        "temperature out":"Out temperature",
        "template":"template",
        "long template":"Extended template",
        "transportation":"Transportation",
        "Transporatation type":"Transportation type",
        "transportation info":"Delivery conditions",
        "thermobox":"Thermobox",
        "Thermobox quantity":"Thermobox quantity",
        "thermobox temperature":"Thermobox temperature",
        "Time can not be less than 3 hours ": " Time can not be less than 3 hours ",
        "no temperature data":"No temperature data",
        "to":"To",
        "total weight":"Total weight",
        "time send from":"Dispatch time",
        "date send from":"Dispatch Date",
        "time receive from":"Delivery time",
        "date receive from":"Delivery Date",
        "time send <br> from":"Dispatch time from",
        "time send <br> to":"Dispatch time to",
        "time receive <br> to":"Delivery time to",
        "time receive <br> from":"Delivery time from",
        "tips":"Tips",
        "total":"Total",
        "type":"Type",
        "use Biocard package":"Use Biocard package",
        "use utoref":"Use Biocard package",
        "volume":"Volume",
        "value":"Estimated cargo value",
        "quantity":"Quantity",
        "Chose the package":" Chose the package ",
        "Cargo will be delivered without temperature control":" Cargo will be delivered without temperature control ",
        "Current project":"Current project",
        "Dispatch from Warehouse":"Dispatch from Warehouse",
        "Status: Dispatched":"Status: Dispatched",
        "Confirmation Date":"Confirmation Date",
        "Flight Date":"Flight Date",
        "Customs clearance date":"Customs clearance date",
        "Shipment #":"Shipment #",
        "Customs Declaration #":" Customs Declaration #",
        "AWB #":" AWB #",
        "Dispatched from customs":"Dispatched from customs",
        "Invoice #":"Invoice #",
        "Cargo places":"Cargo places",
        "Temperature conditions":"Temperature conditions",
        "Cargo Weight":"Cargo Weight",
        "Weight and quantity":"Weight and quantity",
        "width":"Width",
        "weight":"Weight",
        "Your order will be delivered at center":"Shipment will be delivered to site",
        "You should add contact persons by chicking on + button": "You should add contact persons by chicking on + button",
     }
  },
  "ru":{
     "translation":{
        "Не важно": "Не важно",
        "Delivery Date can not be less than that available":"Дата не может быть меньше минимальной доступной",
        "You should add contact persons by chicking on + button":"Необходимо добавить контактных лиц, нажав на плюс",
        "Total gross weight":"Общий вес Брутто",
        "You'll be provided with dry ice. Please chose container without temperature if you have your own dry ice ":"Вам будет предоставлен сухой лёд, если у вас свой сухой лед – выберите бокс без температуры",
        "Cargo will be delivered in Auto-refrigirator wuth controlled temperature ":" Груз будет доставлен в авторефрижераторе с температурным режимом ",
        "Cargo will be delivered in separate vehicle":"Груз будет доставлен отдельным автомобилем грузоподьемностью",
        "Please add cargo information":"Укажите пожалуйста данные по грузу",
        "Your cargo is too large, chose Auto-refregirator for delivery":"Ваш груз слишком большой, выберите авторефрижератор для доставки",
        "Please spend 1 minute to check Hints. It will help you to work with online service. Hints are different on every page.": " Выделите 1 минуту на изучение подсказкок на каждой странице, это поможет вам работать с личным кабинетом намного быстрее. Текст подсказок отличается на каждой странице ",
        "В РАБОТЕ":"В РАБОТЕ",
        "ВЫПОЛНЕН":"ВЫПОЛНЕН",
        "ОТГРУЖЕН":"ОТГРУЖЕН",
        "Отгружено с таможни":"Отгружено с таможни",
        "ЗАЯВКА ПРИНЯТА":"Заявка принята",
        "В случае необходимости уточнения подробностей, менеджер свяжется с Вами.":"В случае необходимости уточнения подробностей, менеджер свяжется с Вами.",
        "ЗАЯВКА ОБРАБОТАНА":"Заявка обработана",
        "Запрос получен": "Запрос получен",
      "Коммерческое предложение отправлено": "Коммерческое предложение отправлено",
      "Заказ подтвержден": "Заказ подтвержден",
      "Закупка инициирована": "Закупка инициирована",
      "Ожидание поступления оплаты": "Ожидание поступления оплаты",
      "Оплата получена": "Оплата получена",
      "Заказ поставщику сформирован": "Заказ поставщику сформирован",
      "Ожидание поступления товара": "Ожидание поступления товара",
      "Заказ выполнен, товар на складе": "Заказ выполнен, товар на складе",
      "Запрос документов": "Запрос документов",
      "Подготовка документов": "Подготовка документов",
      "Согласование": "Согласование",
      "Подготовка заявления в государственный орган": "Подготовка заявления в государственный орган",
      "Подача документов в государственный орган": "Подача документов в государственный орган",
      "Ожидание решения": "Ожидание решения",
      "Получен разрешительный документ": "Получен разрешительный документ",
        "Менеджер проверил Вашу заявку":"Менеджер проверил Вашу заявку",
        "СОЗДАНЫ ЗАДАНИЯ":"Созданы задания",
        "Документы на груз подготовлены":"Документы на груз подготовлены",
        "Груз вручен получателю. Спасибо за Ваш выбор":"Груз вручен получателю. Спасибо за Ваш выбор",
        "Груз доставлен":"Груз доставлен",
        "Курьер принял груз в регионе получателя":"Курьер принял груз в регионе получателя",
        "В ближайшее время груз будет доставлен":"В ближайшее время груз будет доставлен",
        "Подтверждена":"Подтверждена",
        "Подготовка ДТ":"Подготовка ДТ",
        "Готова ДТ":"Готова ДТ",
        "Зарегистрирована ДТ":"Зарегистрирована ДТ",
        "Выпущена ДТ":"Выпущена ДТ",
        "На отгрузке":"На отгрузке",
        "без температуры": "без температуры",
        "Коробка": "Коробка",
        "YES": "ДА",
        "NO": "НЕТ",
        "all":"Все",
        "available":"Доступно",
        "and":"и",
        "address":"Адрес",
        "add": "Добавить",
        "add contact": "Добавить контакт",
        "air":"Воздушные перевозки",
        "add to order":"Добавить в заказ",
        "approved":"Данные верны",
        "approve your order":"Подтвердите ваш заказ",
        "autoref":"Груз будет доставлен в авторефрижераторе",
        "bad":"Плохо",
        "brut":"Брутто",
        "buy":"Купить",
        "back":"назад",
        "box":"Коробка",
        "Back if need":"Необходимость возврата",
        "Cargo information":"Информация о грузе",
        "Cargo name":"Наименование груза",
        "cargo number not assigned":"номер груза не присвоен",
        "Cargo received by Courier":"Груз получен курьером",
        "Courier received cargo in delivery region":"Курьер принял груз в регионе получателя",
        "Cargo will be delivered in shortest terms":"В ближайшее время груз будет доставлен",
        "cargo":"Параметры груза",
        "Delivered":"Груз доставлен",
        "Delivered to consignee":"Груз вручен получателю",
        "Delivery conditions": "Условия доставки",
        "All the cargo places will be delivered by usual transport": "- Все грузовые места будут доставлены общим автотранспортом",
        "All the cargo places will be delivered by separate transport": "- Все грузовые места будут доставлены отдельным автотранспортом указанного обьема",
        "Auto-Refrigerator delivery conditions": "Условия доставки в авторефрижераторе",
        "All the cargo places will be delivered by separate Auto-Refrigerator": "- Все грузовые места будут доставлены в отдельном автомобиле (авторефрижераторе)",
        "If your cargo is supposed to be sent by Air, we'll deliver a cargo in Auto-Refrigerator to the airport only": "- Если ваш груз доставляется самолетом, авторефрижератор используется только для доставки в аэропорт",
        "Cargo will be delivered in Auto-refrigirator wuth controlled temperature": "Груз будет доставлен в авторефрижераторе с температурным режимом",
        "Do not turn": "Не переворачивать",
        "Date requires additional approval by Biocard Manager": "будет дополнительно согласована менеджером компании Biocard",
        "Add more cargo": "Добавить еще груз",
        "Thank you for your choice!":"Спасибо за Ваш выбор",
        "cancel":"Отмена",
        "carantine":"На карантине",
        "create order":"Оформить заказ",
        "Contact information":"Контактная информация",
        "Company sender":"Компания отправитель",
        "Customs Form Title":"Форма таможенного оформления",
        "Customs clearance":"Таможенное оформление",
        "customs":"Таможня",
        "customs order":"Таможенное оформление",
        "comment":"Ваши комментарии к заявке:",
        "contact person":"Контактное лицо",
        "cm":"см",
        "copy":"Скопировать",
        "НАХОДИТСЯ НА ДОСТАВКЕ У КУРЬЕРА":"Находится на доставке у курьера",
        "НАХОДИТСЯ НА ДОСТАВКЕ":"Находится на доставке",
        "close":"Закрыть",
        "choose":"Выберите",
        "choosen cell":"Выбранный элемент",
        "choose box":"Выберите коробку",
        "choose proper temperature":"Выберите нужный температурный режим",
        "choose project":"Выберите проект",
        "choose weight and quantity":"Укажите вес, количество и размеры груза",
        "Chose necessary temperature for auto-refrigerator": " Выберите необходимую температуру авторефрижератора",
        "Mark if temperature logger if needed": " Укажите необходимость термодатчика",
        "Mark vehicle size if needed": " Укажите обьем автомобиля, если необходимо",
        "check if you need thermobox or thermal sensor":"Отметьте, если для перевозки груза нужен термобокс и термодатчик",
        "You can copy your order. New one will be the same, so you'll just need to make some necessary corrections and place an order.": "Вы можете скопировать ваш заказ, нажатием по иконке копирования. Вам нужно будет внести необходимые изменения в новый заказ",
        "You can download an Excel table with necessary orders by clicking an icon on the top right corner.": "Вы можете выгрузить все необходимые заказы в табличку Excel, нажав на иконку в правом верхнем углу",
        "center number":"Номер центра",
        "order number":"Номер заказа",
        "create store order":"Создать заявку на отгрузку",
        "City sender":"Город отправления",
        "City recipient":"Город получения",
        "date & time":"Дата и время",
        "date":"Дата",
        "Dimensions":"Габариты",
        "Delivery terms":"Условия перевозки",
        "Delivery type":"Тип перевозки",
        "Department":"Отдел",
        "delivery":"Добавить в заказ",
        "delivery docs":"Документ курьерской доставки",
        "docs":"Документы",
        "no docs":"Нет загруженных документов",
        "done":"Выполнен",
        "dueTo":"Годен до",
        "download file":"Скачайте файл",
        "drop file":"Перетащите сюда файл, или кликните для загрузки",
        "enter name manually":"Ввести ФИО вручную",
        "ground":"Наземные перевозки",
        "great":"Отлично",
        "height":"Высота",
        "kg":"кг",
        "length":"Длина",
        "leave your feedback":"Оставьте свой отзыв",
        "logout":"Выйти",
        "m3":"м³",
        "Manager checked your request":"Менеджер проверил Вашу заявку",
        "Manager will contact you if you have any questions":"В случае необходимости уточнения подробностей, менеджер свяжется с Вами",
        "name":"ФИО",
        "net":"Нетто",
        "new":"Новый",
        "new order":"Новый Заказ",
        "num":"Номер",
        "number not assigned":"Номер еще не присвоен",
        "no temperature":"без контроля температуры",
        "no store projects":"У вас отсутствуют проекты по доставке товаров со склада, свяжитесь с вашим менеджером чтобы заключить договор",
        "normal":"Нормально",
        "no orders":"Поиск заказов не дал результата, возможно нет заказов за выбранные даты",
        "foreign economic activity":"ВЭД конслатинг",
        "from":"От",
        "forward":"Вперед",
        "Final step":"Отправка",
        "file":"Файл",
        "fix errors":"Необходимо исправить ошибки в файле, отмечены красным",
        "history":"Движения",
        "order":"Заказ",
        "order info":"Подтвердите ваш заказ",
        "order status":"Статус заявки",
        "order from":"заказов из",
        "total orders":"Всего заказов",
        "orders errors":"Заказов с ошибками",
        "orders no errors":"Заказов без ошибок",
        "total errors":"Всего ошибок в файле",
        "outside":"Cнаружи",
        "or":"или",
        "Open": "Открыт",
        "Closed": "Закрыт",
        "phone":"Телефон",
        "Package type":"Характер упаковки",
        "pcs":"шт",
        "provided order num":"Указанный номер заказа",
        "project":"Проект",
        "purchase order":"Поручение",
        "purchasing":"Закупки",
        "please type":"Начните вводить",
        "Prepared Documents":"Документы на груз подготовлены",
        "Previous Hint": "Предыдущая подсказка",
        "Pick up boxes for my product (automatically)": "Подобрать боксы под мой груз (автоматически)",
        "Vehicle, tons": "Транспорт, тонн",
        "Next Hint": "Следующая подсказка",
        "Hide": "Скрыть",
        "Biocard Hints": "Подсказки Biocard",
        "Courier shipments history": "История курьерских доставок",
        "Receiver address":"Адрес получения",
        "Receiver name":"Наименование получателя",
        "recipient":"Получатель",
        "regulatory":"Регуляторика",
        "refrigerator truck":"Предоставить грузовой автомобиль (авторефрижератор)",
        "refrigerator truck temperature": "температура авторефрижератора",
        "rating":"Ваша оценка?",
        "rub":"rub",
        "returned":"Возвращено",
        
        "Please approve your order": "Пожалуйста подтвердите ваш заказ",
        "import":"Импорт",
        "import instruction":"Инструкция по работе с импортом",
        "import subtitle":"Этот раздел личного кабинета поможет вам загружать заказы на перевозку из excel файла",
        "import fill file":"Заполните файл необходимыми данными по заказам, каждый заказ на новой строке",
        "import file upload":"Загрузите ваш файл в форму ниже",
        "import fix errors":"После загрузки все ошибки в файле будут помечены красным цветом, их нужно поправить и отправить заного на проверку",
        "import finish":"Как только ваш файл пройдет полностью валидацию, появится кнопка отправить, и заказы создадутся в системе и отобразяться у вас в списке заказов",
        "info":"ПРИМЕЧАНИЯ",
        "inside":"Внутри",
        "in process":"Выполняется",
        "Sender address":"Адрес отправления",
        "Sender name":"Наименование отправителя",
        "sender":"Отправитель",
        "search":"Поиск..",
        "series":"Серия",
        "shipping":"Морские перевозки",
        "show":"Показывать",
        "sensor":"Термодатчик",
        "sensor autoref":"Отчет температурного режима перевозки (термодатчик)",
        "smart":"Умный датчик",
        "send":"Отправить",
        "send for errors fix":"Отправить на проверку",
        "store":"Склад",
        "stocks":"Остатки на складе",
        "stock":"Остаток",
        "status change":"ИЗМЕНЕНИЯ СТАТУСА",
        "show on map":"На карте",
        "start typing project":"Начните вводить номер проекта",
        "store order":"Заказ на доставку со склада",
        "temperature":"Температура",
        "temperature out":"Температурный выход",
        "template":"шаблона",
        "long template":"расширенный шаблон",
        "thermobox":"Термобокс",
        "Thermobox quantity":"Количество термобоксов",
        "thermobox temperature":"Температура в термобоксе",
        "Time can not be less than 3 hours": "Время не может быть меньше 3 часов",
        "no temperature data":"Нет данных по температуре",
        "no temp": "без температуры",
        "transportation":"Доставка",
        "Transporatation type":"Тип доставки",
        "transportation info":"Параметры доставки",
        "to":"До",
        "tons": "тонн ",
        "total weight":"Общий вес",
        "time send from":"Время отправления",
        "date send from":"Дата отправления",
        "time receive from":"Время получения",
        "date receive from":"Дата получения",
        "time send <br> from":"Время <br> отправления от",
        "time receive <br> from":"Время <br> получения от",
        "time send <br> to":"Время <br> отправления до",
        "time receive <br> to":"Время <br> получения до",
        "tips":"Подсказки",
        "total":"Общий",
        "type":"Тип",
        "Total Items":" Всего позиций ",
        "Copy Order":" Скопировать заявку",
        "Leave a Feedback":"Оставить отзыв",
        "Download attached files":" Посмотреть документы по заявке",
        "Use BIOCARD SafePack package":" Доставить груз в фирменных боксах Biocard",
        "Use cardboard boxes without temperature control":" Доставить груз в коробках без контроля",
        "Provide a vehicle without temperature control":" Предоставить автомобиль без контроля температуры",
        "Provide a vehicle with temperature control":" Предоставить автомобиль с контролем температуры",
        "All cargo places will be delivered as a general cargo":" Все грузовые места будут добавлены общим автотранспортом",
        "Total Cargo Places":" Всего грузовых мест",
        "Internal Box Dimensions":" Внутренние размеры бокса",
        "Chose the package":" Укажите в чем доставить ваш груз",
        "Cargo will be delivered without temperature control":"Груз будет доставлен без контроля температуры со стороны компании",
        "Current project":"Текущий проект",
        "Dispatch from Warehouse":"Отгрузки со склада",
        "Status: Dispatched":" статус: Отгружен",
        "Confirmation Date":"Дата подтверждения",
        "Flight Date":" Дата прилета",
        "Customs clearance date":" Дата выпуска",
        "Shipment #":" Номер груза",
        "Customs Declaration #":"Номер ГТД",
        "AWB #":"Номер авианакладной",
        "Dispatched from customs":"Отгружено с таможни",
        "Invoice #":"Инвойс номер",
        "Cargo places":"Грузовых мест",
        "Temperature conditions":"Температура перевозки",
        "Cargo Weight":" Вес груза",
        "use Biocard package":"Предоставить фирменные боксы для термолабильных грузов",
        "volume":"Объем",
        "value":"Оценочная стоимость",
        "quantity":"Количество",
        "Weight and quantity":"Вес и количество",
        "width":"Ширина",
        "weight":"Вес",
        "Your order will be delivered at center":"Ваш заказ будет доставлен в центр",
        "You can switch pages by clicking tabs on the top": "Вы можете переключаться между страницами склада нажав на вкладки сверху.",
       "You can change a project by clicking currently chosen one on the top": "Вы можете сменить проект на необходимый нажав на кнопку с текущим проектом в шапке.",
       "Search-bar on the top right corner can be used to find any data displayed on the page": "С помощью поисковой строки в шапке страницы, вы можете отбирать товары, как по наименованию так и остальным полям",
       "Enter dimensions and quantity of your cargo, click Add to order. Thermo box will be chosen automatically. Add another cargo if it is necessary. Chose different boxes if it is not allowed to ship several cargoes in one box. ": "Введите размеры и количество вашего груза, нажмите кнопку Добавить в заказ. Бокс будет подобран автоматически. Добавьте еще груз, если необходимо. Если какие либо грузы нельзя перевозить в одном боксе, подбирайте для них боксы раздельно"
     }
  }
}
 export default data;