import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import history from './history';
import { ConnectedRouter } from 'connected-react-router';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import ru from 'date-fns/locale/ru';
// import { I18nextProvider } from "react-i18next";
import i18n from './i18n';

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Router history={history}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
					{/* <I18nextProvider i18n={i18n}> */}
					<App />
					{/* </I18nextProvider> */}
				</MuiPickersUtilsProvider>
			</Router>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
);

if (window.Cypress) {
	window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
