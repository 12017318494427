import axios from "../axios";
import { takeLatest, call, put, throttle } from "redux-saga/effects";

export const moduleName = "purchase";

export const REQUEST_ALL_PURCHASE = moduleName + "/REQUEST_ALL_PURCHASE";
export const RECEIVE_ALL_PURCHASE = moduleName + "/RECEIVE_ALL_PURCHASE";
export const ERROR_ALL_PURCHASE = moduleName + "/ERROR_ALL_PURCHASE";

export const getPurchase = async ({ dateFrom, dateTo, search, userid1c, sort = ['datecis', 'desc'] }) => {
  let body = {
    userid1c,
    datecis_start: dateFrom,
    datecis_end: dateTo,
    sort_field: sort[0],
    sort_type: sort[1],
    search,
  };
  return await axios.post("/purchase/purchase_list.php", body, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};
/*                                            ACTIONS                                            */

export const getAllPurchase = data => ({
  type: REQUEST_ALL_PURCHASE,
  payload: data
});

/*                                           SAGAS                                           */

function* oneOrderEffectSaga(action) {
  try {
    let { data } = yield call(getPurchase, action.payload);
    // yield put(receiveOneOrder(null)); //clear data
    yield put({ type: RECEIVE_ALL_PURCHASE, payload: data });
  } catch (error) {
    yield put({ type: ERROR_ALL_PURCHASE, payload: error.message });
  }
}

export function* allPurchaseWatcher() {
  yield throttle(700, REQUEST_ALL_PURCHASE, oneOrderEffectSaga);
}

export const initialState = {
  data: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALL_PURCHASE:
      return {
        loading: true,
        data: [],
        error: null
      };
    case RECEIVE_ALL_PURCHASE:
      return {
        data: action.payload,
        loading: false,
        error: null
      };
    case ERROR_ALL_PURCHASE:
      return {
        data: [],
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
