export const filtersDelivery = {
  new: "Новые",
  inProcess: "Выполняются",
  done: "Выполнены"
};

/* TABLE HEAD ROWS */
export const contactsRows = [
  { id: "name", numeric: false, disablePadding: true, label: "name" },
  { id: "phone", numeric: true, disablePadding: false, label: "phone" }
];

export const snackBars = {
  success: "Ваш заказ успешно создан!",
  error: "Произошла ошибка создания заказа!"
};

export const dimensionsChoise = {
  height: "Высота",
  length: "Длина",
  width: "Ширина"
};

export const autoValuesChoise = ["1.5", "3", ">5", "Не важно"];

export const storeOrderRows = [
  { id: "#", numeric: false, disablePadding: true, label: "#" },
  {
    id: "Cargo name",
    numeric: false,
    disablePadding: true,
    label: "Наименование"
  },
  {
    id: "temperature",
    numeric: true,
    disablePadding: false,
    label: "t°C хранения"
  },
  { id: "stock", numeric: true, disablePadding: false, label: "Всего, шт" },
  { id: "type", numeric: true, disablePadding: false, label: "Тип" },
  { id: "", numeric: true, disablePadding: false, label: "" }
];

export const storeStocksRows = [
  { id: "series", numeric: true, disablePadding: false, label: "Серия" },
  { id: "dueTo", numeric: true, disablePadding: false, label: "Срок годности" },
  {
    id: "available",
    numeric: true,
    disablePadding: false,
    label: "Доступно, шт"
  },
  {
    id: "carantine",
    numeric: true,
    disablePadding: false,
    label: "На карантине, шт"
  },
  {
    id: "returned",
    numeric: true,
    disablePadding: false,
    label: "Возвращено, шт"
  },
  {
    id: "delivery",
    numeric: true,
    disablePadding: false,
    label: "Добавить в заказ"
  }
];

export const storeOrderTableRows = [
  { id: "#", numeric: false, disablePadding: true, label: "" },
  {
    id: "Cargo name",
    numeric: false,
    disablePadding: true,
    label: "Наименование"
  },
  { id: "series", numeric: true, disablePadding: false, label: "Серия" },
  {
    id: "temperature",
    numeric: true,
    disablePadding: false,
    label: "Температура хранения"
  },
  { id: "quantity", numeric: true, disablePadding: false, label: "Количество" }
];

export const statusMap = {
  "0": "НОВЫЙ",
  "1": "В РАБОТЕ",
  "2": "ВЫПОЛНЕН"
};

export const tempMap = {
  temp2: "Температура снаружи",
  temp1: "Температура внутри"
};

export const temperatureGuidMap = {
  "d8d24581-f12a-11e6-80c6-0cc47ac15091": "Без температуры",
  "970f0609-eee7-11e6-80c6-0cc47ac15091": "-15 -25",
  "e01bb3b2-00cd-11e7-80c6-0cc47ac15091": "Авторефрижератор",
  "0 +4": "020f3a0c-9c9b-11e9-81df-ac1f6b00d3ad",
  "ce56d94b-5d9d-11e7-80b7-ac1f6b00d3ad": "-2 +5",
  "7c0face7-ec7f-11e6-80c6-0cc47ac15091": "+2 +8",
  "892e971b-eee7-11e6-80c6-0cc47ac15091": "+15 +25",
  "933dbee5-9a3a-11e8-8133-ac1f6b00d3ad": "+2 +25",
  "9e27c2f2-eee7-11e6-80c6-0cc47ac15091": "-70",
  "020f3a0c-9c9b-11e9-81df-ac1f6b00d3ad": "0 +4"
};

export const temperatureMap = {
  "без температуры": "d8d24581-f12a-11e6-80c6-0cc47ac15091",
  "-15 -25": "970f0609-eee7-11e6-80c6-0cc47ac15091",
  авторефрижератор: "e01bb3b2-00cd-11e7-80c6-0cc47ac15091",
  "0 +4": "020f3a0c-9c9b-11e9-81df-ac1f6b00d3ad",
  "-2 +5": "ce56d94b-5d9d-11e7-80b7-ac1f6b00d3ad",
  "+2 +8": "7c0face7-ec7f-11e6-80c6-0cc47ac15091",
  "+15 +25": "892e971b-eee7-11e6-80c6-0cc47ac15091",
  "+2 +25": "933dbee5-9a3a-11e8-8133-ac1f6b00d3ad",
  "-70": "9e27c2f2-eee7-11e6-80c6-0cc47ac15091"
};

export const rowsCargo = [
  {
    id: "Cargo name",
    numeric: false,
    disablePadding: true,
    label: "Наименование груза",
    primary: true
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Количество, шт"
  },
  {
    id: "weight",
    numeric: true,
    disablePadding: false,
    label: "Вес Брутто, кг"
  },
  { id: "length", numeric: true, disablePadding: false, label: "Длина" },
  { id: "width", numeric: true, disablePadding: false, label: "Ширина" },
  { id: "height", numeric: true, disablePadding: false, label: "Высота" },
  {
    id: "temperature",
    numeric: true,
    disablePadding: false,
    label: "Температура"
  },
  { id: "thermobox", numeric: true, disablePadding: false, label: "Термобокс" },
  { id: "sensor", numeric: true, disablePadding: false, label: "Термодатчик" }
];

export const rowsCargoSingle = [
  {
    id: "Cargo name",
    numeric: false,
    disablePadding: true,
    label: "Наименование груза",
    primary: true
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Количество, шт"
  },
  { id: "weight", numeric: true, disablePadding: false, label: "Вес, кг" },
  {
    id: "temperature",
    numeric: true,
    disablePadding: false,
    label: "Температурный режим"
  },
  {
    id: "temperature_outside",
    numeric: true,
    disablePadding: false,
    label: "Температура снаружи"
  },
  {
    id: "temperature_intside",
    numeric: true,
    disablePadding: false,
    label: "Температура внутри"
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Местоположение"
  }
];

export const boxWeights = {
  "SafePack 05": 5.1,
  "SafePack 09": 9,
  "SafePack 20": 14,
  "SafePack 32": 23,
  "SafePack 50": 22,
  "SafePack 80": 40,
  "SafePack 64": 35.5,
  "SafePack 100": 37.5,
  "SafePack PS-1": 105
};
