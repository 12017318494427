import axios from "../axios";
import history from "../history";
import { receiveOneProject } from "./storeSaga";
import { takeLatest, call, put } from "redux-saga/effects";
import sha1 from "sha1";

export const moduleName = "auth";

/*                                            ACTIONS                                            */

export const LOGIN_WATCHER = `${moduleName}/LOGIN_WATCHER"`;
export const UPDATE_PROFILE = `${moduleName}/UPDATE_PROFILE`;
export const LOG_OUT_WATCHER = `${moduleName}/LOG_OUT_WATCHER`;
export const LOG_OUT_DONE = `${moduleName}/LOG_OUT_DONE`;

export function loginWatcher(authParams) {
  return { type: LOGIN_WATCHER, payload: authParams };
}

export function updateProfile(authParams) {
  return { type: UPDATE_PROFILE, payload: authParams };
}

export function logoutWatcher() {
  return { type: LOG_OUT_WATCHER, payload: null };
}

export function logoutHandle() {
  return { type: LOG_OUT_DONE, payload: null };
}

/*                                           SAGAS                                           */

function loginApi(authParams) {
  let formData = new FormData();
  formData.append("login", authParams.login);
  formData.append("password", sha1(authParams.password));
  return axios.post("/auth/login/index.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

function* loginEffectSaga(action) {
  try {
    localStorage.clear();
    let { data } = yield call(loginApi, action.payload);
    // store data to localStorage
    // dispatch action to change redux state
    yield put(updateProfile(data));
    // redirect to home route after successful login
    if (!data.result_message_id) {
      localStorage.setItem("authData", JSON.stringify(data));
      let prevPath = history.location.state
        ? history.location.state.from.pathname
        : "/transportation";
      history.push(prevPath);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* loginWatcherSaga() {
  yield takeLatest(LOGIN_WATCHER, loginEffectSaga);
}

function* logoutEffectSaga() {
  try {
    localStorage.clear();
    // dispatch action to change redux state
    yield put(logoutHandle());
    yield put(receiveOneProject());
    // redirect to home route after successful login
    history.push("/login");
  } catch (e) {
    console.error(e);
  }
}

export function* logoutWatcherSaga() {
  yield takeLatest(LOG_OUT_WATCHER, logoutEffectSaga);
}

export const initialState = {
  profile: {
    result_message_id: 1
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    case LOG_OUT_DONE:
      let newProfile = {};
      newProfile.result_message_id = 1;
      return {
        ...state,
        profile: newProfile
      };
    default:
      return state;
  }
};

export default reducer;
