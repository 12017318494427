import React from 'react';
import axios from '../../axios';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		margin: 'auto'
	}
});

class RedirectToSingle extends React.Component {
	componentWillMount() {
		let { search } = this.props.obj.location;
		let searchParams = new URLSearchParams(search);
		if (searchParams.get('slk')) {
			axios
				.get('/x/' + this.props.obj.location.search)
				.then((res) => {
					console.log(res.data.id);
					this.props.obj.history.push('singleOrder/' + res.data.id);
				})
				.catch((err) => console.log(err));
		} else {
			window.location.assign('https://lk.biocard.com/site/vieworder' + search);
		}
	}

	render() {
		const { classes } = this.props;

		return (
			<Paper className={classes.paper} component='div'>
				<h2>Перенаправялем вас на страницу заказа</h2>
			</Paper>
		);
	}
}

export default withStyles(styles)(RedirectToSingle);
