import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
//material components
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import ArrowBack from "@material-ui/icons/ArrowBackIosOutlined";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import { FaSignOutAlt } from "react-icons/fa";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
//components
import Flag from "react-world-flags";

// import Breadcrumbs from "./breadcrumbs/breadcrumbs";
import { useTranslation } from "react-i18next";
//router
import { Link } from "react-router-dom";
//styles
import styles from "./NavBarStyles";

const NavBarContainer = props => {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchor] = useState(null);
  const [mobileMoreAnchorEl, setmMbileMoreAnchorEl] = useState(null);

  const handleProfileMenuOpen = event => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchor(null);
    handleMobileMenuClose();
  };

  const changeLanguage = lang => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    handleMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setmMbileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setmMbileMoreAnchorEl(null);
  };

  const { classes, handleLogout, handleDrawerOpen, open } = props;
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => changeLanguage("ru-RU")}>
        <div style={{ marginRight: 10 }}>
          <Flag code="RU" height="20px" widht="20px" />
        </div>
        Русский
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("en-EN")}>
        <div style={{ marginRight: 10 }}>
          <Flag code="GB" height="15px" widht="15px" />
        </div>
        English
      </MenuItem>
      <hr />
      {/* <MenuItem onClick={handleLogout}>
        <div style={{ marginRight: 10 }}>
          <LeaveIcon />
        </div>
        {t("logout")}
      </MenuItem> */}
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={this.handleMobileMenuClose}>
					<IconButton color='inherit'>
						<Badge badgeContent={4} color='secondary'>
							<MailIcon />
						</Badge>
					</IconButton>
					<p>Сообщения</p>
				</MenuItem>
				<MenuItem onClick={this.handleMobileMenuClose}>
					<IconButton color='inherit'>
						<Badge badgeContent={11} color='secondary'>
							<NotificationsIcon />
						</Badge>
					</IconButton>
					<p>Уведомления</p>
				</MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Профиль</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: false
        })}
      >
        <Toolbar>
          <IconButton
            className={classNames(
              classes.menuButton,
              {
                [classes.hide]: !open
              },
              classes.sectionDesktop
            )}
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
          >
            {open ? <ArrowBack /> : <MenuIcon />}
          </IconButton>

          <div className={classes.title}>
            <Link to="/transportation">
              <img
                styles={{ width: "150", height: "40" }}
                href="transportation"
                src="https://biocard.com/wp-content/uploads/2018/06/logo.png"
                className="custom-logo"
                alt="Логистические услуги в сфере здравоохранения"
                itemProp="logo"
              />
            </Link>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <IconButton>
								<Badge badgeContent={1} color='secondary'>
									<MailIcon />
								</Badge>
							</IconButton>
							<IconButton>
								<Badge badgeContent={17} color='secondary'>
									<NotificationsIcon />
								</Badge>
							</IconButton> */}
            <div>
              <Typography variant="body2" color="textPrimary">
                {props.profile.Name}
              </Typography>
            </div>
            <div style={{ margin: 15, cursor: "pointer" }}>
              <Flag
                onClick={handleProfileMenuOpen}
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                code={
                  i18n.language.split("-")[0] === "en"
                    ? "GB"
                    : i18n.language.split("-")[0].toUpperCase()
                }
                height={i18n.language.split("-")[0] !== "en" ? "25px" : "20px"}
                widht="35px"
              />
            </div>
            {/* <IconButton
              aria-owns={isMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle />
            </IconButton> */}
            <div style={{ marginRight: 10, color: "black" }}>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={handleLogout}
              >
                <FaSignOutAlt />
              </IconButton>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-owns={isMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle />
            </IconButton>
            {/* <IconButton aria-haspopup='true' onClick={this.handleMobileMenuOpen} color='inherit'>
								<MoreIcon />
							</IconButton> */}
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
    </div>
  );
};

NavBarContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NavBarContainer);
