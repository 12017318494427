import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as form } from "redux-form";

import authReducer, { moduleName as authModule } from "../sagas/sessionSaga";
import storeReducer, { moduleName as storeModule } from "../sagas/storeSaga";
import orderReducer, { moduleName as orderModule } from "../sagas/deliverySaga";
import orderCreateReducer, {
  moduleName as orderCreateModule
} from "../sagas/createOrderSaga";
import importReducer, { moduleName as importModule } from "../sagas/importSaga";
import customsReducer, {
  moduleName as customsModule
} from "../sagas/customsSaga";
import regulReducer, { moduleName as regulModule } from "../sagas/regulSaga";
import purchaseReducer, {
  moduleName as purchaseModule
} from "../sagas/purchaseSaga";

export default history =>
  combineReducers({
    router: connectRouter(history),
    form,
    [authModule]: authReducer,
    [storeModule]: storeReducer,
    [orderModule]: orderReducer,
    [orderCreateModule]: orderCreateReducer,
    [importModule]: importReducer,
    [customsModule]: customsReducer,
    [regulModule]: regulReducer,
    [purchaseModule]: purchaseReducer
  });
