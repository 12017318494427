import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Link, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 450,
    minWidth: 450,
    position: "fixed",
    top: "70%",
    left: "15%",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    zIndex: 100,
  },
  avatar: {
    backgroundColor: "#660000"
  },
  link: {
    cursor: "pointer"
  }
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  let { openHelp, page, setOpen, setPage, data } = props;
  const { t } = useTranslation();


  const changePage = page => {
    if (page < 0) {
      setPage(0);
      return;
    }
    if (page >= data.length) {
      setPage(data.length - 1);
      return;
    }
    setPage(page);
  };
  if (!data) return null;
  if (!data[page]) { // if no data for instructions on current page, start from page 0
    page = 0;
  }


  return (
    <Card className={classes.card} style={{ display: openHelp ? 'block' : 'none' }}>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>B</Avatar>}
        title={
          <span style={{ color: "#660000", fontWeight: 500 }}>
            {t('Biocard Hints')}
          </span>
        }
        action={
          <Link data-type="hide-help" className={classes.link} onClick={() => setOpen(false)}>
            {t('Hide')}
          </Link>
        }
        subheader={t(data[page].title)}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {t(data[page].data)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Grid container justify="space-between">
          <Grid item>
            <Link className={classes.link} onClick={() => changePage(page - 1)}>
              {t('Previous Hint')}
            </Link>
          </Grid>
          <Grid item>
            <Link className={classes.link} onClick={() => changePage(page + 1)}>
              {t('Next Hint')}
            </Link>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
