import axios from '../axios';
import { takeLatest, call, put } from 'redux-saga/effects';

import { statusMap } from '../utils/data';

export const moduleName = 'orders';

export const REQUEST_ALL_PROJECTS = moduleName + '/REQUEST_ALL_PROJECTS';
export const RECEIVE_ALL_PROJECTS = moduleName + '/RECEIVE_ALL_PROJECTS';

export const ERROR_ALL = moduleName + '/ERROR_ALL';

export const RECEIVE_ONE_PROJECT = moduleName + '/RECEIVE_ONE_PROJECT';

export const REQUEST_ALL_ORDERS = moduleName + '/REQUEST_ALL_ORDERS';
export const RECEIVE_ALL_ORDERS = moduleName + '/RECEIVE_ALL_ORDERS';

export const REQUEST_ONE_ORDER = moduleName + '/REQUEST_ONE_ORDER';
export const RECEIVE_ONE_ORDER = moduleName + '/RECEIVE_ONE_ORDER';

export const CLEAR_ORDER = moduleName + '/CLEAR_ORDER';

/*                                            ACTIONS                                            */

export function requestOrdersProjects(userId) {
	return { type: REQUEST_ALL_PROJECTS, payload: userId };
}

export function receiveProjects(projects) {
	return { type: RECEIVE_ALL_PROJECTS, payload: projects };
}

export function receiveError(error) {
	return { type: ERROR_ALL, payload: error };
}

export function requestOrders(userId, offset, filter, search, dateFrom, dateTo) {
	return {
		type: REQUEST_ALL_ORDERS,
		payload: { userId, offset, filter, search, dateFrom, dateTo }
	};
}

export function receiveOrders(orders = {}) {
	return { type: RECEIVE_ALL_ORDERS, payload: orders };
}

/*                                           SAGAS                                           */

export const getProjectsDelivery = async (userId) => {
	let formData = new FormData();
	formData.append('iduser1c', userId);
	return await axios.post('/prj/list.php', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

function* projectsEffectSaga(action) {
	try {
		let { data } = yield call(getProjectsDelivery, action.payload);
		// ;
		data = data.data.reduce((prev, cur) => {
			return {
				...prev,
				[cur.prjname]: cur.prj_id
			};
		}, {});
		localStorage.setItem('projects', JSON.stringify(data));
		yield put(receiveProjects(data));
	} catch (e) {
		console.error(e);
		receiveError(e);
	}
}

export function* projectsOrdersWatcherSaga() {
	yield takeLatest(REQUEST_ALL_PROJECTS, projectsEffectSaga);
}

export const getOrders = async ({ userId, offset, filter, search, dateFrom, dateTo }) => {
	let formData = new FormData();
	formData.append('iduser1c', userId);
	formData.append('limit', 1000);
	formData.append('offset', offset);
	formData.append('orderby', 'datecis');
	formData.append('ascdesc', 'desc');
	formData.append('search', search);
	formData.append('filter_status', filter);
	formData.append('date_from', dateFrom);
	formData.append('date_to', dateTo);
	return await axios.post('/orders/list11.php', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

function* ordersEffectSaga(action) {
	try {
		let { data } = yield call(getOrders, action.payload);
		data = data.data.reduce((prev, cur) => {
			let status = statusMap[cur.status];
			return {
				...prev,
				[cur.row_number]: {
					date: cur.datecis,
					status,
					project: cur.proekt_name,
					sender: cur.sender_name,
					send_from: cur.sender_date_start,
					sender_address: cur.sender_address_full,
					receive_to: cur.recipient_date_end,
					receiver: cur.recipient_name,
					receiver_address: cur.recipient_address_full,
					doc_id: cur.doc_id,
					numbercis: cur.numbercis,
					datecis: cur.datecis,
					t_alerts: cur.t_alerts,
					t_alerts_num: cur.t_alerts_num,
					shipment: cur.shipment,
					row_number: cur.row_number,
					id: cur.id,
				}
			};
		}, {});
		yield put(receiveOrders(data));
	} catch (e) {
		console.error(e);
		receiveError(e);
	}
}

/*                                            ACTIONS                                            */

export function* ordersWatcherSaga() {
	yield takeLatest(REQUEST_ALL_ORDERS, ordersEffectSaga);
}

export function requestOneOrder(id, userId) {
	return { type: REQUEST_ONE_ORDER, payload: { id, userId } };
}

export const receiveOneOrder = (order) => {
	return { type: RECEIVE_ONE_ORDER, payload: order };
};

export const clearOrder = () => {
	return { type: CLEAR_ORDER };
};

export const receiveOneProject = (project) => {
	return { type: RECEIVE_ONE_PROJECT, payload: project };
};

export const getOneOrder = async (id, iduser1c) => {
	let body = { id, iduser1c };
	return await axios.post('/orders/detail_fast9.php', body, {
		headers: {
			'Content-Type': 'application/json'
		}
	});
};

/*                                           SAGAS                                           */

function* oneOrderEffectSaga(action) {
	try {
		let { data } = yield call(getOneOrder, action.payload.id, action.payload.userId);
		let newData;
		if (data.type_id === 2) {
			data['1c_order_status'].c_full_json[0].cargos = data['1c_order_status'].cargo_details;
			newData = data['1c_order_status'].c_full_json[0];
			newData.doclist = data['1c_order_status'].doclist;
			newData.map = data['1c_order_status'].map;
		}
		if (data.type_id === 1) {
			newData = data['in_react_form'].orders[0];
		}
		newData.summ_massa = data.summ_massa;

		if (!data.type_id) {
			newData = 0;
		}
		newData.statuses = data.statuses;
		yield put(receiveOneOrder(newData));
	} catch (e) {
		console.error(e);
		receiveError(e);
	}
}

export function* oneOrderWatcherSaga() {
	yield takeLatest(REQUEST_ONE_ORDER, oneOrderEffectSaga);
}

export const initialState = {
	projects: [],
	project: '',
	orders: {},
	order: {
		sender: {
			address: {},
			contacts: []
		},
		reciever: {
			address: {},
			contacts: []
		}
	},
	loadingOrders: false,
	loading: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_ALL_PROJECTS:
			return {
				...state,
				projects: action.payload,
				loading: false
			};
		case RECEIVE_ALL_ORDERS:
			return {
				...state,
				orders: { ...action.payload },
				loadingOrders: false
			};
		case RECEIVE_ONE_PROJECT:
			return {
				...state,
				project: action.payload
			};
		case RECEIVE_ONE_ORDER:
			return {
				...state,
				order: {
					...state.order,
					...action.payload
				},
				loading: false
			};
		case CLEAR_ORDER:
			return {
				...state,
				order: {
					...initialState.order
				}
			};
		case REQUEST_ONE_ORDER:
			return {
				...state,
				loading: true
			};
		case REQUEST_ALL_ORDERS:
			return {
				...state,
				loadingOrders: true
			};
		case REQUEST_ALL_PROJECTS:
			return {
				...state,
				loading: true
			};
		case ERROR_ALL:
			return {
				...state,
				error: action.payload
			};
		default:
			return state;
	}
};

export default reducer;
