import axios from "axios";

let baseURL;

switch (process.env.REACT_APP_API_HOSTNAME) {
  case "my.biocard.com":
    baseURL = "https://apiprod01.biocard.com/api";
    break;
  case "test":
    baseURL = "https://apitest.biocard.com/api";
    break;
  default:
    baseURL = "https://apitest.biocard.com/api";
    break;
}

export default axios.create({
  baseURL//: 'https://apiprod01.biocard.com/api' //
});
