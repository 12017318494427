import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


const spinnerComponent = (props) => {
	return (
		<Grid item xs={12} lg={12} md={12} style={{ justifyContent: 'center', height: `${props.height}px` }}>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress size={60} thickness={5} />
			</div>
		</Grid>
	);
};

export default spinnerComponent;
