import React from 'react';
import { postErrorToDb } from '../../utils/apiRequests';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';

const styles = (theme) => ({
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		margin: 'auto'
	}
});
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		localStorage.clear();
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		// if (error.contains('Loading chunk')) {
		// 	this.props.push('/transportation')
		// }
		postErrorToDb(
			this.props.auth.Name,
			error,
			errorInfo.componentStack,
			window.navigator.userAgent
		).then((data) => console.log(data));
		// SEND error to db
	}

	render() {
		let { classes } = this.props;
		if (this.state.errorInfo) {
			// Error path
			return (
				<Paper className={classes.paper} component='div'>
					<h2>Произошла ошибка</h2>
					<p>Пожалуйста перезагрузите страницу</p>
					<p>Отчет уже отправлен разработчикам!</p>
				</Paper>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth.profile,
});

const mapDispathToProps = (state) => ({
	push: push,
})

export default connect(mapStateToProps, mapDispathToProps)(withStyles(styles)(ErrorBoundary));
